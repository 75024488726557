import React, { useState } from "react";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { googleAuthProvider } from "../firebase";
import "./signIn.css"

function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Use useNavigate hook

    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const token = await userCredential.user.getIdToken();
            await axios.post(
                "/loginBackend",
                { token },
                {
                    withCredentials: true,
                }
            );

            navigate("/");
        } catch (error) {
            setError("Invalid Credentials");
            console.error("Error signing in:", error);
        }
    };


    const handleSignUp = async () => {
        try {
            navigate("/signUp");
        } catch (error) {
            console.error("Error redirecting to sign up:", error);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleAuthProvider);
            const token = await result.user.getIdToken();
            document.cookie = `authToken=${token}; path=/; secure; samesite=strict;`;
            navigate("/");
        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };

    return (
        <div className="form-container">
            <form className="signup-form" onSubmit={handleSignIn}>
                {/*structura pro heading and logo*/}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "20px", justifyContent: "center" }}>
                    <img alt = "companyLogo" src="./assets/companyLogo_white.png" style={{ height: "35px", width: "35px" }}></img>
                    <label style={{ fontWeight: "500", fontSize: "20px", marginLeft: "10px" }}>Structura Pro</label>
                </div>
                {/*welcome back tag line*/}
                <label style={{ marginTop: "10px", marginBottom: "10px", fontWeight: "500" }}>Welcome to Structura Pro!</label>
                {/*input fields*/}
                {error && (
                    <label style={{ color: "red", marginTop: "10px", marginBottom: "10px" }}>Invalid username or password. Please try loggin in again.</label>
                )}
                <div className="input-group">
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
                </div>
                <div className="input-group">
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                </div>
                {/*login button*/}
                <button className="btn btn-primary" type="submit">Login</button>
            </form>
            <div className="alternative-signin">
                <button className="btn btn-google" onClick={handleGoogleSignIn}>
                    <img src="./assets/googleLogo.png" alt="Google logo" className="google-logo" />
                    Login with Google
                </button>
                <hr style={{ width: "390px", height: "0.5px", backgroundColor: "#F5F5F5", marginTop: "22.5px" }} />
                <label style={{ marginTop: "15px", marginBottom: "15px" }}>Not already a member? Sign Up today!</label>
                <button className="btn btn-secondary" onClick={handleSignUp}>
                    Sign Up using email
                </button>
            </div>
        </div>
    );
}


export default SignIn;
