import "./designPagesStyles.css";
import React, { useRef, useState } from "react";
import InputSheet from "../../components/inputSheet/inputSheet";
import axios from "axios";
import ComponentHolder from "../../components/NewComponents/ComponentHolder";
import ExpandableSection from "../../components/NewComponents/ExpandableSection";
import Buttons from "../../components/buttons/buttons";
import ColumnBucklingDiagram from "../../components/diagrams/columnBucklingDiagram";
import BeamAnalysisEnergyBar from "../../components/chart/columnBuckling/BeamAnalysisEnergyBar";
import BeamAnalysisChart from "../../components/chart/columnBuckling/BeamAnalysisChart";

function ColumnBucklingSimulator() {
    const [inputs, setInputs] = useState({
        sectionType: "Circular",
        radius: 100,
        lengthX: 300,
        lengthY: 300,
        columnLength: 1000,
        modulustElasticity: 200000,
        initialImperfection: "Small",
        emparicalConstant: 0.75,
        appliedLoad: 1000,
        endCondition: "Pin-Pin",
    });

    // const [geometryInformationOverlayVisibility, setGeometryInformationOverlayVisibility] = useState(false);
    const [chartData, setChartData] = useState({ x: [], y: [] });
    const [analytics, setAnalytics] = useState([]);
    const [energy, setEnergy] = useState({});
    const [hideData, setHideData] = useState("");
    const [conditionMap, setConditionMap] = useState({
        "Pin-Pin": { dataKey: "pinnedPinned", k: 1 },
        "Fix-Fix": { dataKey: "fixedFixed", k: 0.9 },
        "Fix-Pin": { dataKey: "fixedPinned", k: 0.9 },
        "Fix-Free": { dataKey: "fixedFree", k: 2.1 },
    });

    const inputDataRefs = {
        input1Ref: useRef(null),
        input2Ref: useRef(null),
        input3Ref: useRef(null),
        input4Ref: useRef(null),
        input5Ref: useRef(null),
        input6Ref: useRef(null),
        input7Ref: useRef(null),
        input8Ref: useRef(null),
        input9Ref: useRef(null),
        input10Ref: useRef(null),
    };

    // Input Variables
    const inputData = {
        input1Ref: ["Geometric Properties", "Section Type", ["dropdown", inputDataRefs.input1Ref, ["Circular", "Rectangular"]], ""],
        input2Ref: ["Geometric Properties", "Radius", ["number", inputDataRefs.input2Ref, 100], "mm", inputDataRefs.input1Ref.current?.value === "Circular"],
        input3Ref: ["Geometric Properties", "Length X", ["number", inputDataRefs.input3Ref, 300], "mm", inputDataRefs.input1Ref.current?.value === "Rectangular"],
        input4Ref: ["Geometric Properties", "Length Y", ["number", inputDataRefs.input4Ref, 300], "mm", inputDataRefs.input1Ref.current?.value === "Rectangular"],
        input5Ref: ["Geometric Properties", "Column Length", ["number", inputDataRefs.input5Ref, 1000], "mm"],
        input6Ref: ["Material Properties", "Modulus of Elasticity", ["number", inputDataRefs.input6Ref, 200000], "MPa"],
        input7Ref: ["Material Properties", "Initial Imperfection", ["dropdown", inputDataRefs.input7Ref, ["Small", "Moderate", "Large"]], ""],
        input8Ref: ["Material Properties", "Empirical Constant", ["number", inputDataRefs.input8Ref, 0.75], ""],
        input9Ref: ["Force and End Conditions", "Applied Load", ["number", inputDataRefs.input9Ref, 1000], "kN"],
        input10Ref: ["Force and End Conditions", "End Condition", ["dropdown", inputDataRefs.input10Ref, ["Pin-Pin", "Fix-Fix", "Fix-Pin", "Fix-Free"]], ""],
    };

    //information overlay functions
    // const geometryInformationButtonClicked = () => {
    //     setGeometryInformationOverlayVisibility(true);
    // };

    const informationOverlayFunctions = [];

    const calculateButtonClicked = async () => {
        const newInputs = {
            sectionType: inputDataRefs.input1Ref.current.value,
            radius: inputDataRefs.input2Ref.current.value,
            lengthX: inputDataRefs.input3Ref.current.value,
            lengthY: inputDataRefs.input4Ref.current.value,
            columnLength: inputDataRefs.input5Ref.current.value,
            modulustElasticity: inputDataRefs.input6Ref.current.value,
            initialImperfection: inputDataRefs.input7Ref.current.value,
            emparicalConstant: inputDataRefs.input8Ref.current.value,
            appliedLoad: inputDataRefs.input9Ref.current.value,
            endCondition: inputDataRefs.input10Ref.current.value,
        };
        // Update state immediately
        setInputs(newInputs);
        // Call API using the newInputs values directly
        try {
            const { dataKey } = conditionMap[newInputs.endCondition];

            const url = `/api/columnBucklingSimulation?endCondition=${dataKey}&L=${newInputs.columnLength}&P=${newInputs.appliedLoad}&E=${newInputs.modulustElasticity}&beta=${newInputs.emparicalConstant}&initialImperfection=${newInputs.initialImperfection}&sectionType=${newInputs.sectionType}&lengthX=${newInputs.lengthX}&lengthY=${newInputs.lengthY}&radius=${newInputs.radius}`;

            const response = await axios.get(url);
            const data = response.data;
            setChartData({
                x: data.x,
                y: data.deflection,
            });
            setEnergy(data.energyStored);
            setAnalytics(data.analytics);
            setHideData("");
            // if applied load is less than critical load, hide the data
            if (data.analytics[1].data[0].value > parseFloat(newInputs.appliedLoad)) {
                setHideData("Applied Load is less than Critical Load, Deflection is negligible and hence ignored.");
                setChartData({ x: [0, newInputs.columnLength], y: [0, 0] });
            }
        } catch (error) {
            setChartData({ x: [], y: [] });
            setEnergy({});
            setAnalytics([]);
            setHideData("Please check the input values and try again.");
        }
    };

    const informationButtonClicked = () => {
        window.open("../assets/columnBucklingSimulator/designManual.pdf", "_blank");
    };

    // Title Bar Buttons
    const titleBarButtons = {
        calculationButton: ["Calculate", calculateButtonClicked],
        informationButton: ["Information", informationButtonClicked],
    };

    const renderedTitleBarButtons = [];
    Object.keys(titleBarButtons).forEach((key) => {
        renderedTitleBarButtons.push(<Buttons type="textButton" text={titleBarButtons[key][0]} fontColor="black" backgroundColor="" linkTo="" onClickFunction={titleBarButtons[key][1]} />);
    });

    return (
        <>
            <div className="_containerDiv">
                {/* Information overlays */}
                <div className="_designInputDiv">
                    <div className="_designInputsTitleBar">
                        <label className="_dashboardHeading">Design Inputs</label>
                    </div>
                    <div className="_designInputs">
                        <InputSheet inputData={inputData} updateCalculationsFunction={calculateButtonClicked} informationOverlayFunctions={informationOverlayFunctions} />
                    </div>
                </div>
                <div className="_designDashboardDiv">
                    <div className="_dashboardTitleBar">
                        <label className="_dashboardHeading" style={{ color: "black" }}>
                            Column Buckling Simulator
                        </label>
                        <div className="_dashboardButtons">{renderedTitleBarButtons}</div>
                    </div>

                    <div className="_dashboardTiles">
                        <div id="_dashboardLeftDiv" className="_dashboardSection">
                            <ComponentHolder id="dashboardTopLeftDiv" title="Diagram">
                                {chartData && chartData.x.length > 0 ? <ColumnBucklingDiagram chartData={chartData} L={inputs.columnLength} endCondition={inputs.endCondition} forceTop={inputs.appliedLoad + " kN"} forceBottom="P" /> : <div>Press "Calculate" or change design inputs to show analysis results.</div>}
                            </ComponentHolder>
                            <ComponentHolder id="dashboardBottomLeftDiv" title="Deflection Diagram">
                                {chartData && chartData.x.length > 0 ? <BeamAnalysisChart xAxisData={chartData.x} yAxisData={chartData.y} xAxisLabel={"Column Length (mm)"} yAxisLabel={"Lateral Deflection (mm)"} title={"Buckling Mode Shape"} width="100%" inverse={true} /> : <div>Press "Calculate" or change design inputs to show analysis results.</div>}
                            </ComponentHolder>
                        </div>
                        <div id="_dashBoardRightDiv" className="_dashboardSection">
                            <ComponentHolder id="dashboardTopRightDiv" title="Analysis Results">
                                <div>{analytics && analytics.length > 0 ? analytics.map((section, index) => <ExpandableSection key={index} title={section.heading} data={section.data} toFixed={4} />) : <div>Press "Calculate" or change design inputs to show analysis results.</div>}</div>
                            </ComponentHolder>
                            <ComponentHolder id="dashboardBottomRightDiv" title="Strain Energy Stored">
                                {energy && Object.keys(energy).length > 0 ? <BeamAnalysisEnergyBar data={energy} currentCondition={conditionMap[inputs.endCondition].dataKey} /> : <div>Press "Calculate" or change design inputs to show analysis results.</div>}
                            </ComponentHolder>
                        </div>
                    </div>
                    <div className="_dashboardStatusBar">
                        <label>{hideData}</label>
                        <label>Structura Pro</label>
                    </div>
                </div>
                {/* {geometryInformationOverlayVisibility && (
                    <InformationOverlay
                        informationOverlayHeading="Geometric Properties"
                        informationOverlayCaption={["Geometric Properties: Longitudinal View"]}
                        informationOverlayImageSrc={["..\\assets\\beamAnalysis\\images\\geometricInformationOverlay.png"]}
                        closeInformationOverlays={() => {
                            setGeometryInformationOverlayVisibility(!geometryInformationOverlayVisibility);
                        }}
                    />
                )} */}
            </div>
        </>
    );
}

export default ColumnBucklingSimulator;
