import "./designPagesStyles.css";
import Buttons from "../../components/buttons/buttons";
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import SettingsMenu from "../../components/settingsMenu/settingsMenu";
//import CalculationSheet from "../../components/calculationSheet/calculationSheet";
import InputSheet from "../../components/inputSheet/inputSheet";
import { pdf } from "@react-pdf/renderer";
import InformationOverlay from "../../components/informationOverlay/informationOverlay";
import CalculationSheet from "../../components/calculationSheet/calculationSheet";
import RectangularColumnSectionDiagram from "../../components/diagrams/rectangularColumnSectionDiagram";
import RectangularColumnLongitudinalDiagram from "../../components/diagrams/rectangularColumnLongitudinalDiagram";
import RectangularSolidSectionLoadingDiagram from "../../components/diagrams/rectangularSolidSectionLoadingDiagram";
import RectangularConcreteColumnReinforcementDiagram from "../../components/diagrams/rectangularConcreteColumnReinforcementDiagram";
import ChartComponent from "../../components/chart/chartComponent";
import CostAndQuantity from "../../components/tables/costAndQuantity/costAndQuantity";
import ConcreteBeamDesignPDF from "../../components/pdf/concreteBeamDesignPDF";
import LoadingOverlay from "../../components/loadingOverlay/loadingOverlay";

function RectangularSolidConcreteColumnOptimizer() {
    //****************************************INPUT REFS AND INPUT VARIABLES****************************************
    //Ref Variables
    const inputDataRefs = {
        minDxRef: useRef(null),
        maxDxRef: useRef(null),
        minDyRef: useRef(null),
        maxDyRef: useRef(null),
        heightRef: useRef(null),
        fyRef: useRef(null),
        fckRef: useRef(null),
        densityOfSteelRef: useRef(null),
        modulusOfElasticityOfSteelRef: useRef(null),
        axialForceRef: useRef(null),
        momentAboutXRef: useRef(null),
        momentAboutYRef: useRef(null),
        effectiveLengthFactorRef: useRef(null),
        coverRef: useRef(null),
        unitCostOfSteelRef: useRef(null),
        unitCostOfConcreteRef: useRef(null),
    };

    //Input Variables
    const inputData = {
        //geometric properties
        minDx: ["Geometric Properties", "Min. Dx", ["number", inputDataRefs.minDxRef, 300], "mm"],
        maxDx: ["Geometric Properties", "Max. Dx", ["number", inputDataRefs.maxDxRef, 600], "mm"],
        minDy: ["Geometric Properties", "Min. Dy", ["number", inputDataRefs.minDyRef, 300], "mm"],
        maxDy: ["Geometric Properties", "Max. Dy", ["number", inputDataRefs.maxDyRef, 600], "mm"],
        height: ["Geometric Properties", "Unrestrained Height", ["number", inputDataRefs.heightRef, 3500], "mm"],
        //material properties
        fy: ["Material Properties", "fy", ["number", inputDataRefs.fyRef, 415], "MPa"],
        fck: ["Material Properties", "fck", ["dropdown", inputDataRefs.fckRef, [15, 20, 25, 30, 35, 40]], "MPa"],
        densityOfSteel: ["Material Properties", "Density of Steel", ["number", inputDataRefs.densityOfSteelRef, 7850], "kg/cu.m"],
        modulusOfElasticityOfSteel: ["Material Properties", "Modulus of Elasticity", ["number", inputDataRefs.modulusOfElasticityOfSteelRef, 200000], "MPa"],
        //applied forces
        axialForce: ["Applied Forces", "Axial Force", ["number", inputDataRefs.axialForceRef, 600], "kN"],
        momentAboutX: ["Applied Forces", "Moment about X", ["number", inputDataRefs.momentAboutXRef, 150], "kN-m"],
        momentAboutY: ["Applied Forces", "Moment about Y", ["number", inputDataRefs.momentAboutYRef, 300], "kN-m"],
        //setup information
        effectiveLengthFactor: ["Setup Information", "Effective Length Factor", ["number", inputDataRefs.effectiveLengthFactorRef, 1], ""],
        //reinforcement setup
        cover: ["Reinforcement Setup", "Cover", ["number", inputDataRefs.coverRef, 40], "mm"],
        //cost information
        unitCostOfSteel: ["Cost Information", "Unit Cost of Steel", ["number", inputDataRefs.unitCostOfSteelRef, 70], "INR/kg"],
        unitCostOfConcrete: ["Cost Information", "Unit Cost of Concrete", ["number", inputDataRefs.unitCostOfConcreteRef, 9200], "INR/cu.m"]
    };

    ///****************************************DIAGRAMS****************************************
    //selected diagaram type state variable
    const [selectedDiagramType, setSelectedDiagramType] = useState("sectionDiagram"); //which diagram has been selected in the diagram section
    //diagram div state variables
    const diagramDivRef = useRef(null); // ref for the div containing the diagram
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); //dimensions of the div containing the diagram

    //resizing the diagram when the screen size changes
    useEffect(() => {
        const observeTarget = diagramDivRef.current;
        const resizeObserver = new ResizeObserver((entries) => {
            // Assuming you want to observe the first entry
            const { width, height } = entries[0].contentRect;
            setDimensions({ width, height });
        });
        // Step 2: Initialize the ResizeObserver
        if (observeTarget) {
            resizeObserver.observe(observeTarget);
        }

        // Step 3: Clean up
        return () => {
            if (observeTarget) {
                resizeObserver.disconnect();
            }
        };
    }, []); // Empty dependency array ensures this effect runs only once

    //handle dropdown change for selecting diagram
    const handleDiagramDropdownChange = (event) => {
        setSelectedDiagramType(event.target.value);
    };

    ///****************************************DESIGN RESULTS****************************************
    const [selectedDesignResult, setSelectedDesignResult] = useState("reinforcementDiagram");

    //handle the change in selected design result
    const handleDesignResultsDropdownChange = (event) => {
        setSelectedDesignResult(event.target.value);
    }

    //****************************************CALCULATE BUTTON****************************************
    //front end state variables
    const [designStatusMessage, setDesignStatusMessage] = useState("Status: Click calculate to design beam.")
    const [designCalculationsVisibility, setDesignCalculationsVisibility] = useState(false); //whether calculations are shown or not
    const [fetchingData, setFetchingData] = useState(false); //tracker to let us know when we are fetching data and need to show the laoding overlay
    const [calculationButtonClicked, setCalculationButtonClicked] = useState(false);

    //design calculations data
    const [designCalculations, setDesignCalculations] = useState({}); //to store the calcualation data we get from back end
    const [interactionCurveDataset, setInteractionCurveDataset] = useState({});
    const [reinforcementSet, setReinforcementSet] = useState([]);
    const [designSummaryForPDF, setDesignSummaryForPDF] = useState({}); //this is the design summary we are printing in section 5 of the design PDF
    const [costAndQuantity, setCostAndQuantity] = useState({}); //cost and quantity
    const [optimizedDx, setOptimizedDx] = useState(0);
    const [optimizedDy, setOptimizedDy] = useState(0);

    let cancelTokenSource = null;

    //handle the clickdown of calculate button
    const calculateButtonClicked = async () => {
        //set design status message
        setDesignStatusMessage("Status: Generating optimized column section...")
        setFetchingData(true);
        setCalculationButtonClicked(true);
        //cancel the pending requests if there are any
        if (cancelTokenSource) {
            cancelTokenSource.cancel("Operation cancelled due to new request");
        }
        //create a new cancel token source
        cancelTokenSource = axios.CancelToken.source();

        //try to get the optimized section from the optimizer function
        try {
            //Create request object
            const requestObject = {};
            Object.keys(inputDataRefs).forEach((key) => {
                const keyName = key.replace(/Ref$/, ""); //Removing the "Ref" suffix from each of the key name
                requestObject[`${keyName}`] = inputDataRefs[key].current.value;
            });
            //send this request to the backend
            axios.post("/api/fetchRectangularSolidConcreteColumnOptimizedSection", requestObject, {
                cancelToken: cancelTokenSource.token,
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                //store optimized section data in state variables
                setOptimizedDx(response.data["optimizedDx"]);
                setOptimizedDy(response.data["optimizedDy"]);
                //set the loader to false
                setFetchingData(false);
            });
        } catch (error) {
            console.log(`could not fetch the optimzed section due to ${error}`)
        }
    };

    useEffect(() => {
        if (calculationButtonClicked) {
            try {
                const designRequestObject = {
                    dX: optimizedDx,
                    dY: optimizedDy,
                    height: inputDataRefs.heightRef.current ? inputDataRefs.heightRef.current.value : "",
                    fy: inputDataRefs.fyRef.current ? inputDataRefs.fyRef.current.value : "",
                    fck: inputDataRefs.fckRef.current ? inputDataRefs.fckRef.current.value : "",
                    densityOfSteel: inputDataRefs.densityOfSteelRef.current ? inputDataRefs.densityOfSteelRef.current.value : "",
                    modulusOfElasticityOfSteel: inputDataRefs.modulusOfElasticityOfSteelRef.current ? inputDataRefs.modulusOfElasticityOfSteelRef.current.value : "",
                    axialForce: inputDataRefs.axialForceRef.current ? inputDataRefs.axialForceRef.current.value : "",
                    momentAboutX: inputDataRefs.momentAboutXRef.current ? inputDataRefs.momentAboutXRef.current.value : "",
                    momentAboutY: inputDataRefs.momentAboutYRef.current ? inputDataRefs.momentAboutYRef.current.value : "",
                    effectiveLengthFactor: inputDataRefs.effectiveLengthFactorRef.current ? inputDataRefs.effectiveLengthFactorRef.current.value : "",
                    cover: inputDataRefs.coverRef.current ? inputDataRefs.coverRef.current.value : "",
                    unitCostOfSteel: inputDataRefs.unitCostOfSteelRef.current ? inputDataRefs.unitCostOfSteelRef.current.value : "",
                    unitCostOfConcrete: inputDataRefs.unitCostOfConcreteRef.current ? inputDataRefs.unitCostOfConcreteRef.current.value : ""
                }

                //Send data to back end
                axios.post("/api/fetchRectangularSolidConcreteColumnDesignCalculations", designRequestObject, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then((response) => {
                    setDesignCalculations(response.data["designCalculations"]); //Set design calculations state variable
                    setInteractionCurveDataset(response.data["interactionCurveDataset"]);
                    setReinforcementSet(response.data["finalReinforcementSet"]);
                    setCostAndQuantity(response.data["costAndQuantity"]);

                    //set the design variables that we have to change things on teh front end
                    setDesignCalculationsVisibility(true); //Set design calculations visibilitys
                    setCalculationButtonClicked(false);
                    setDesignStatusMessage("Status: Column design complete.")
                });
            } catch (error) {
                console.log(`could not fetch the calculation because ${error}`);
            }
        } else {
            setDesignStatusMessage("Status: Column design complete.")
        }
    }, [optimizedDx, optimizedDy])

    //****************************************SETTINGS BUTTON****************************************
    //settings menu state variables
    const [displaySettingsMenu, setDisplaySettingsMenu] = useState(false); //toggle to show or not show the settings menu
    const [designedBy, setDesignedBy] = useState("John Doe"); //who has design the structural element (this is going to appear in the final PDF that we are going to print out)
    const [checkedBy, setCheckedBy] = useState("Alice Alex"); //who checked the design calculations (this is going to appear in the final PDF that we are going to print out)
    const [allowableDCR, setAllowableDCR] = useState(1); //what is the DCR that is allowed?

    //hangle clickdown of settings button
    const settingsButtonClicked = () => {
        setDisplaySettingsMenu(true);
    };

    //****************************************PRINT BUTTON****************************************
    //handle clickdown of print pdf button
    const printButtonClicked = async () => {
        if (designCalculationsVisibility) {
            //take current values from all input fields and prepare input data for PDF
            const inputDataForPDF = {};
            Object.keys(inputDataRefs).forEach((key) => {
                const keyName = key.replace(/Ref$/, "");
                inputDataForPDF[`${keyName}`] = [inputData[keyName][0], inputData[keyName][1], inputDataRefs[key].current.value, inputData[keyName][3]];
            });

            //get the svg image that we have to convert
            const sectionDiagram = await convertSvgToPng("sectionDiagramForPDF", 300, 500);
            const longitudinalDiagram = await convertSvgToPng("longitudinalDiagramForPDF", 300, 500);
            const loadingDiagram = await convertSvgToPng("loadingDiagramForPDF", 300, 500);

            const diagrams = {
                figure1: ["Figure 1: Section Diagram", sectionDiagram],
                figure2: ["Figure 2: Longitudinal Diagram", longitudinalDiagram],
                figure3: ["Figure 3: Loading Diagram", loadingDiagram]
            };

            const reinforcementDiagram = await convertSvgToPng("reinforcementDiagramForPDF", 300, 500);

            //generate pdf from blob
            const blob = await pdf(
                <ConcreteBeamDesignPDF header={"Rectangular Solid Concrete Column Design"}
                    inputDataForPDF={inputDataForPDF} //current inputs that we have from the user
                    calculationDataForPDF={designCalculations} //calculations data that we get from the backend
                    designSummaryForPDF={designSummaryForPDF} //design capacity ratios
                    diagrams={diagrams}
                    reinforcementDiagram={reinforcementDiagram}
                    designedBy={designedBy}
                    checkedBy={checkedBy}
                />,
            ).toBlob();

            //create url to pdf blob
            const url = URL.createObjectURL(blob);

            //open pdf in new window
            window.open(url, "_blank");
        } else {
            alert(`Please design element by clicking "Calculate" before printing out the design sheet.`);
        }
    };

    //****************************************FEEDBACK BUTTON****************************************
    const feedbackButtonClicked = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSfdBBJP4jNDOLV_ZjkIsvkNhsrcCc4A3xaCU7TyLmN_aunQyQ/viewform?usp=sf_link", "_blank");
    }

    //****************************************INFORMATION BUTTON****************************************
    //handle the clickdown of information button
    const informationButtonClicked = () => {
        window.open("../assets/rectangularHollowSteelBeam/designManual/rectangularHollowSteelBeamDesignManual.pdf", "_blank");
    };

    //****************************************INFORMATION OVERLAYS****************************************
    //information overlay state variables
    const [geometryInformationOverlayVisibility, setGeometryInformationOverlayVisibility] = useState(false);
    const [materialPropertiesInformationOverlayVisibility, setMaterialPropertiesInformationOverlayVisibility] = useState(false);
    const [appliedForcesInformationOverlayVisibility, setAppliedForcesInformationOverlayVisibility] = useState(false);
    const [setupInformationOverlayVisibility, setSetupInformationOverlayVisibility] = useState(false);

    //information overlay functions
    const geometryInformationButtonClicked = () => {
        setGeometryInformationOverlayVisibility(true);
    };

    const materialPropertiesInformationButtonClicked = () => {
        setMaterialPropertiesInformationOverlayVisibility(true);
    };

    const appliedForcesInformationButtonClicked = () => {
        setAppliedForcesInformationOverlayVisibility(true);
    };

    const setupInformationButtonClicked = () => {
        setSetupInformationOverlayVisibility(true);
    };

    const closeInformationOverlays = () => {
        setGeometryInformationOverlayVisibility(false);
        setMaterialPropertiesInformationOverlayVisibility(false);
        setAppliedForcesInformationOverlayVisibility(false);
        setSetupInformationOverlayVisibility(false);
    };

    const informationOverlayFunctions = [geometryInformationButtonClicked, materialPropertiesInformationButtonClicked, appliedForcesInformationButtonClicked, setupInformationButtonClicked];

    //****************************************TITLE BAR BUTTONS****************************************
    //title bar buttons
    const titleBarButtons = {
        calculateButton: ["Calculate", calculateButtonClicked],
        settingsButton: ["Settings", settingsButtonClicked],
        printButton: ["Print", printButtonClicked],
        informationButton: ["Information", informationButtonClicked],
        feedbackButton: ["Feedback", feedbackButtonClicked]
    };

    const renderedTitleBarButtons = [];
    Object.keys(titleBarButtons).forEach((key) => {
        renderedTitleBarButtons.push(<Buttons type="textButton" text={titleBarButtons[key][0]} fontColor="black" backgroundColor="" linkTo="" onClickFunction={titleBarButtons[key][1]} />);
    });

    return (
        <>
            <div className="_containerDiv">
                {fetchingData && (
                    <LoadingOverlay />
                )}
                {displaySettingsMenu && (
                    <SettingsMenu setDisplaySettingsMenuFunction={setDisplaySettingsMenu}
                        designedBy={designedBy}
                        setDesignedBy={setDesignedBy}
                        checkedBy={checkedBy}
                        setCheckedBy={setCheckedBy}
                        allowableDCR={allowableDCR}
                        setAllowableDCR={setAllowableDCR} />
                )}
                {geometryInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Geometric Properties"
                        informationOverlayCaption={["Geometric Properties: Section View", "Geometric Properties: Longitudinal View"]}
                        informationOverlayImageSrc={["..\\assets\\rectangularHollowSteelSection\\geometricPropertiesInformationOverlay_1.png", "..\\assets\\rectangularHollowSteelSection\\geometricPropertiesInformationOverlay_2.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {materialPropertiesInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Material Properties"
                        informationOverlayCaption={["Rectangular Hollow Steel Section: Material Properties"]}
                        informationOverlayImageSrc={["..\\assets\\rectangularHollowSteelSection\\materialPropertiesInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {appliedForcesInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Applied Forces"
                        informationOverlayCaption={["Rectangular Hollow Steel Section: Applied Forces"]}
                        informationOverlayImageSrc={["..\\assets\\rectangularHollowSteelSection\\forcesAppliedInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {setupInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Setup Information"
                        informationOverlayCaption={["Rectangular Hollow Steel Section: Effective Length Factors from IS800:2007 (Table 11)"]}
                        informationOverlayImageSrc={["..\\assets\\rectangularHollowSteelSection\\setupInformationInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}

                <div className="_designInputDiv">
                    <div className="_designInputsTitleBar">
                        <label className="_dashboardHeading" style={{ fontize: "22px" }}>Design Inputs</label>
                    </div>
                    <div className="_designInputs">
                        <InputSheet inputData={inputData} updateCalculationsFunction={() => { setDesignStatusMessage("Status: Design inputs have been changed. Click calculate to re-design column.") }} informationOverlayFunctions={informationOverlayFunctions} />
                    </div>
                </div>
                <div className="_designDashboardDiv">
                    <div className="_dashboardTitleBar">
                        <label className="_dashboardHeading" style={{ color: "black", fontSize: "22px" }}>
                            Rectangular Concrete Column Optimizer
                        </label>
                        <div className="_dashboardButtons">{renderedTitleBarButtons}</div>
                    </div>
                    <div className="_dashboardTiles">
                        <div id="_dashboardLeftDiv" className="_dashboardSection">
                            <div id="dashboardTopLeftDiv" className="_dashboardTile">
                                <div className="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>Diagrams</label>
                                    <select className="_dashboardTileTitleBarDropdown" value={selectedDiagramType} onChange={handleDiagramDropdownChange}>
                                        <option value="sectionDiagram">Section Diagram</option>
                                        <option value="longitudinalDiagram">Longitudinal Diagram</option>
                                        <option value="loadingDiagram">Loading Diagram</option>
                                    </select>
                                </div>
                                <div className="_tileContentDiv" ref={diagramDivRef}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px", fontSize: "14px" }}>Press "Calculate" to show diagrams for the optimized column section.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <>
                                            <svg className="diagramSVG" id="sectionDiagram" style={{ display: selectedDiagramType == "sectionDiagram" ? "block" : "none" }}>
                                                <RectangularColumnSectionDiagram frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    height={optimizedDy}
                                                    width={optimizedDx}
                                                    stroke="black"
                                                    fill="#F5F5F5" />
                                            </svg>
                                            <svg className="diagramSVG" id="longitudinalDiagram" style={{ display: selectedDiagramType == "longitudinalDiagram" ? "block" : "none" }}>
                                                <RectangularColumnLongitudinalDiagram frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    height={optimizedDy}
                                                    width={optimizedDx}
                                                    stroke="black"
                                                    fill="#F5F5F5" />
                                            </svg>
                                            <svg className="diagramSVG" id="loadingDiagram" style={{ display: selectedDiagramType == "loadingDiagram" ? "block" : "none" }}>
                                                <RectangularSolidSectionLoadingDiagram frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    momentAboutX={inputDataRefs.momentAboutXRef.current ? inputDataRefs.momentAboutXRef.current.value : ""}
                                                    momentAboutY={inputDataRefs.momentAboutYRef.current ? inputDataRefs.momentAboutYRef.current.value : ""}
                                                    height={optimizedDy}
                                                    width={optimizedDx} />
                                            </svg>
                                            <svg className="diagramSVG" id="sectionDiagramForPDF" style={{ display: "none" }}>
                                                <RectangularColumnSectionDiagram frameHeight={300}
                                                    frameWidth={500}
                                                    height={optimizedDy}
                                                    width={optimizedDx}
                                                    stroke="black"
                                                    fill="#F5F5F5" />
                                            </svg>
                                            <svg className="diagramSVG" id="longitudinalDiagramForPDF" style={{ display: "none" }}>
                                                <RectangularColumnLongitudinalDiagram frameHeight={300}
                                                    frameWidth={500}
                                                    height={optimizedDy}
                                                    width={optimizedDx}
                                                    stroke="black"
                                                    fill="#F5F5F5" />
                                            </svg>
                                            <svg className="diagramSVG" id="loadingDiagramForPDF" style={{ display: "none" }}>
                                                <RectangularSolidSectionLoadingDiagram frameHeight={300}
                                                    frameWidth={500}
                                                    momentAboutX={inputDataRefs.momentAboutXRef.current ? inputDataRefs.momentAboutXRef.current.value : ""}
                                                    momentAboutY={inputDataRefs.momentAboutYRef.current ? inputDataRefs.momentAboutYRef.current.value : ""}
                                                    height={optimizedDy}
                                                    width={optimizedDx} />
                                            </svg>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div id="dashboardBottomLeftDiv" className="_dashboardTile">
                                <label className="_tileHeading">Cost and Quantity</label>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show cost and quantity results.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <>
                                            <CostAndQuantity heading={["Description", "Value"]}
                                                data={costAndQuantity} />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div id="_dashBoardRightDiv" className="_dashboardSection">
                            <div id="dashboardTopRightDiv" className="_dashboardTile">
                                <label className="_tileHeading">Design Calculations</label>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show design calculations.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <CalculationSheet calculationData={designCalculations} />)
                                    }
                                </div>
                            </div>
                            <div id="dashboardBottomRightDiv" className="_dashboardTile">
                                <div className="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>Design Results</label>
                                    <select className="_dashboardTileTitleBarDropdown" value={selectedDesignResult} onChange={handleDesignResultsDropdownChange}>
                                        <option value="reinforcementDiagram">Reinforcement Diagram</option>
                                        <option value="interactionDiagramY">Interaction Diagram (Moment about Y)</option>
                                        <option value="interactionDiagramX">Interaction Diagram (Moment about X)</option>
                                    </select>
                                </div>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }} ref={diagramDivRef}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show design results.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <>
                                            <svg className="diagramSVG" id="reinforcementDiagram" style={{ display: selectedDesignResult == "reinforcementDiagram" ? "block" : "none" }}>
                                                <RectangularConcreteColumnReinforcementDiagram frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    dX={optimizedDx}
                                                    dY={optimizedDy}
                                                    reinforcementSet={reinforcementSet} />
                                            </svg>
                                            <svg className="diagramSVG" id="reinforcementDiagramForPDF" style={{ display: "none" }}>
                                                <RectangularConcreteColumnReinforcementDiagram frameHeight={300}
                                                    frameWidth={500}
                                                    dX={optimizedDx}
                                                    dY={optimizedDy}
                                                    reinforcementSet={reinforcementSet} />
                                            </svg>
                                            <div style={{ display: selectedDesignResult == "interactionDiagramY" ? "block" : "none" }}>
                                                <ChartComponent frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    xDataset={interactionCurveDataset["mur_y_final"]}
                                                    yDataset={interactionCurveDataset["pur_y_final"]}
                                                    xAxisTitle="Mur (kN-m)"
                                                    yAxisTitle="Pur (kN)"
                                                    appliedAxialLoad={inputDataRefs.axialForceRef.current ? inputDataRefs.axialForceRef.current.value : ""}
                                                    appliedMoment={inputDataRefs.momentAboutYRef.current ? inputDataRefs.momentAboutYRef.current.value : ""} />
                                            </div>
                                            <div style={{ display: selectedDesignResult == "interactionDiagramX" ? "block" : "none" }}>
                                                <ChartComponent frameHeight={dimensions.height}
                                                    frameWidth={dimensions.width}
                                                    xDataset={interactionCurveDataset["mur_x_final"]}
                                                    yDataset={interactionCurveDataset["pur_x_final"]}
                                                    xAxisTitle="Mur (kN-m)"
                                                    yAxisTitle="Pur (kN)"
                                                    appliedAxialLoad={inputDataRefs.axialForceRef.current ? inputDataRefs.axialForceRef.current.value : ""}
                                                    appliedMoment={inputDataRefs.momentAboutXRef.current ? inputDataRefs.momentAboutXRef.current.value : ""} />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="_dashboardStatusBar">
                        <label>{designStatusMessage}</label>
                        <label>Structura Pro</label>
                    </div>
                </div>
            </div>
        </>
    );
}

// Function to convert SVG to PNG
async function convertSvgToPng(svgID, height, width) {
    const svgElement = document.getElementById(svgID);
    const svgData = new XMLSerializer().serializeToString(svgElement);

    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const image = new Image(); // Use the standard Image constructor

        image.onload = function () {
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0);

            // Convert canvas to data URL
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
        };

        image.onerror = function (error) {
            reject(error);
        };

        image.src = "data:image/svg+xml;base64," + btoa(svgData);
    });
}

export default RectangularSolidConcreteColumnOptimizer;
