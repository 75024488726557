import "./titleBar.css";
import Buttons from "../../buttons/buttons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SignIn } from "../../SignIn";

function TitleBar() {
    const renderedMiddleSectionButtons = [];
    const renderedEndSectionButtons = [];
    const navigate = useNavigate();

    const careerForm = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSeSJTVWx5WuSpeSWseGvA_LOXEHJ4oK0FvKkymEpayaBMFVWw/viewform?usp=sf_link", "_blank");
    };

    const isTokenPresent = () => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith("authToken=")) {
                return true;
            }
        }
        return false;
    };

    const middleSectionButtons = {
        // structGPT: ["linkButton", "StructGPT", "black", "", "/structGPT", () => { }],             removed for initial release
        home: ["linkButton", "Home", "black", "", "/", () => { }],
        designTools: ["linkButton", "Design Tools", "black", "", "/designTools", () => { }],
        articles: ["linkButton", "Articles", "black", "", "/articles", () => { }],
        aboutUs: ["linkButton", "About Us", "black", "", "/aboutUs", () => { }],
        // community: ["linkButton", "Community", "black", "", "/comingSoon", () => { }]                removed for initial release
    };

    const endSectionButtons = {
        career: ["textButton", "Career", "black", "", "", careerForm],
        login: isTokenPresent() ? ["linkButton", "Profile", "black", "", "/profile", () => { }] : ["linkButton", "Login", "black", "", "/signIn", () => { }],
    };

    Object.keys(middleSectionButtons).forEach((key) => {
        renderedMiddleSectionButtons.push(<Buttons type={middleSectionButtons[key][0]} text={middleSectionButtons[key][1]} fontColor={middleSectionButtons[key][2]} backgroundColor={middleSectionButtons[key][3]} linkTo={middleSectionButtons[key][4]} onClickFunction={middleSectionButtons[key][5]} />);
    });

    Object.keys(endSectionButtons).forEach((key) => {
        renderedEndSectionButtons.push(<Buttons type={endSectionButtons[key][0]} text={endSectionButtons[key][1]} fontColor={endSectionButtons[key][2]} backgroundColor={endSectionButtons[key][3]} linkTo={endSectionButtons[key][4]} onClickFunction={endSectionButtons[key][5]} />);
    });

    return (
        <>
            <div className="_titleBarDiv">
                <div className="_startSectionDivV2">
                    <Link to="/" className="_titleBarLink">
                        <img alt = "company logo" src="../../assets/companyLogo_white.png" className="_titleBarCompanyLogo" />
                        <label className="_titleBarHeading"> Structura Pro </label>
                    </Link>
                </div>
                <div className="_middleSectionDivV2">{renderedMiddleSectionButtons}</div>
                <div className="_endSectionDivV2">{renderedEndSectionButtons}</div>
            </div>
        </>
    );
}

export default TitleBar;
