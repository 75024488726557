import Rectangle from "./elements/rectangle";
import DimensionLine from "./elements/dimensionLine";
import Legend from "./elements/legend";
import DottedLine from "./elements/dottedLine";

//frameHeight and frameWidth: these are the heights and widths of the frame that you want to place your diagram in
//height: height is the actual height of the section that you have
//width: width is the actual width of the section that you have
//stroke: color for the boundary of the diagram
//fill: color for the fill inside the diagram

function RectangularColumnSectionDiagram({ frameHeight, frameWidth, height, width, stroke, fill }) {
    const frameCenterX = frameWidth / 2;
    const frameCenterY = frameHeight / 2;
    const aspectRatio = width / height;
    const displayHeight = (frameHeight) * 0.25;
    const displayWidth = displayHeight * aspectRatio;
    const dottedLineExtension = 20;
    const legend = {
        height: ["black", `Dy: ${height} mm`],
        width: ["black", `Dx: ${width} mm`],
    };

    return (
        <>
            {/*rectangle cross section*/}
            <Rectangle originX={frameCenterX} originY={frameCenterY} height={displayHeight} width={displayWidth} stroke={stroke} fill={fill} />
            {/*height dimension line*/}
            <DimensionLine originX={frameCenterX + displayWidth / 2 + 40} originY={frameCenterY} spanDirection="vertical" span={displayHeight} text={`${height} mm`} textAlignment="right" stroke="black" />
            {/*width dimension line*/}
            <DimensionLine originX={frameCenterX} originY={frameCenterY + displayHeight / 2 + 40} spanDirection="horizontal" span={displayWidth} text={`${width} mm`} textAlignment="bottom" stroke="black" />
            {/*x axis*/}
            <DottedLine originX={frameCenterX} originY={frameCenterY} length={displayWidth + dottedLineExtension} spanDirection="horizontal" stroke="darkgrey" />
            <text x={frameCenterX + displayWidth / 2 + dottedLineExtension / 2 + 10} y={frameCenterY} fill="darkgrey" dominantBaseline="middle" textAnchor="middle">
                X
            </text>
            <text x={frameCenterX - displayWidth / 2 - dottedLineExtension / 2 - 10} y={frameCenterY} fill="darkgrey" dominantBaseline="middle" textAnchor="middle">
                X
            </text>
            {/*y axis*/}
            <DottedLine originX={frameCenterX} originY={frameCenterY} length={displayHeight + dottedLineExtension} spanDirection="vertical" stroke="darkgrey" />
            <text x={frameCenterX} y={frameCenterY + displayHeight / 2 + dottedLineExtension / 2 + 10} fill="darkgrey" dominantBaseline="middle" textAnchor="middle">
                Y
            </text>
            <text x={frameCenterX} y={frameCenterY - displayHeight / 2 - dottedLineExtension / 2 - 10} fill="darkgrey" dominantBaseline="middle" textAnchor="middle">
                Y
            </text>
            {/*legend*/}
            <Legend originX={10} originY={frameCenterY} sideLength={5} verticalSpacing={20} items={legend} />
        </>
    );
}

export default RectangularColumnSectionDiagram;
