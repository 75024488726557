import LatexCompiler from "../../components/latexCompiler/latexCompiler";
import TitleBar from "../../components/titleBar/v2/titleBar";
import "./articles.css";
import Footer from "../../components/footer/footer";

function ImageAndCaption({ imageSrc, caption }) {
    return (
        <div style={{ width: "100%", padding: "10px", alignItems: "center", display: "flex", flexDirection: "column" }}>
            <img alt = "" src={imageSrc} style={{ width: "60%", height: "auto", display: "block" }} />
            <label>{caption}</label>
        </div>
    )
}

const DesignChecksTable = () => {
    return (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse" }}>
                <thead>
                    <tr>
                        <th style={{ border: "1px solid black", padding: "8px" }}>S. No.</th>
                        <th style={{ border: "1px solid black", padding: "8px" }}>Design Check</th>
                        <th style={{ border: "1px solid black", padding: "8px" }}>Clause</th>
                        <th style={{ border: "1px solid black", padding: "8px" }}>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ border: "1px solid black", padding: "8px" }}>1</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Min. Clear Cover Requirements (based on exposure conditions)</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Table 16</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Refer mentioned table</td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black", padding: "8px" }}>2</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Min. Clear Cover Requirements (based on fire exposure)</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Table 16A</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Refer mentioned table</td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black", padding: "8px" }}>3</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Clear Distance Between Bars</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Cl. 26.3.3</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Please refer mentioned clause</td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black", padding: "8px" }}>4</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Min. Flexural Reinforcement for Beams</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Cl. 26.5.1.1</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}><LatexCompiler latexInput={String.raw`$A_{st,min} = \frac{0.85bd}{f_{y}}$`} /></td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black", padding: "8px" }}>5</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Min. Flexural Reinforcement for Slabs</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Cl. 26.5.2</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Please refer mentioned clause</td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black", padding: "8px" }}>6</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Max. Flexural Reinforcement in Beams</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Cl. 26.5.1</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}><LatexCompiler latexInput={String.raw`$0.04bD$`} /></td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black", padding: "8px" }}>7</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Max. Deflection</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}>Cl. 23.2a</td>
                        <td style={{ border: "1px solid black", padding: "8px" }}><LatexCompiler latexInput={String.raw`$l/250$`} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

function ConcreteBeamISCodeArticle() {
    const equations = {
        eq1: String.raw`$F_{c} = F_{st}$`,
        eq2: String.raw`$0.362f_{ck}x_{u}b = 0.87f_{y}A_{st}$`,
        eq3: String.raw`$\mathbf{x_{u} = \frac{0.87f_{y}A_{st}}{0.362f_{ck}b}}$`,
        eq4: String.raw`$\mathbf{M_{u} = 0.362f_{ck}x_{u}b(d-0.416x_{u})}$`,
        eq5: String.raw`$\mathbf{A_{st}^2}\frac{0.87f_{y}^2}{f_{ck}b} - \mathbf{A_{st}}0.87f_{y}d + M_{u} = 0$`,
        eq6: String.raw`$M_{u} = M_{u,lim} + \Delta M_{u}$`,
        eq7: String.raw`$A_{st} = A_{st,lim} + \Delta A_{st}$`,
        eq8: String.raw`$\Delta A_{st} = \frac{M_{u} - M_{u,lim}}{0.87f_{y}(d-d')}$`,
        eq9: String.raw`$A_{sc} = \frac{(0.87f_{y})(\Delta A_{st})}{f_{sc} - 0.447f_{ck}}$`
    }

    return (
        <>
            <TitleBar />
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", overflowY: "scroll", marginBottom: "10px" }}>
                <div style={{ width: "60%", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "15px", paddingBottom: "10px", width: "100%", borderBottom: "1px solid black" }}>
                        <label className="_articleContentTitle">Concrete Beam Design Methods: Indian Standard Code (IS456:2000)</label>
                        <label>Utkarsh Gupta | September 8, 2024</label>
                    </div>
                    <label className="_articleContentHeading">Table of Contents</label>
                    <div className="_listContainer">
                        <ol>
                            <li><a href="#introduction">Introduction</a></li>
                            <li><a href="#designCheckSummary">Design Check Summary</a></li>
                        </ol>
                    </div>
                    <label className="_articleContentHeading" id="introduction">1. Introduction</label>
                    <p>This article provides a concise guideline for designing concrete beams according to the methods and formulas specified in the Indian Standard Code IS 456:2000.</p>
                    <label className="_articleContentHeading" id="designCheckSummary">2. Design Checks Summary</label>
                    Design of concrete beams has certain checks that should be preformed before concluding the design as complete. The table below lists out all necessary checks and the respective clauses as per IS456:2000.
                    <DesignChecksTable />
                    <label className="_articleContentHeading" id="designMethodology">3. Design Methodology</label>
                    <label className="_articleContentSubHeading" id="singlyReinforcedBeam">3.1. Singly Reinforced Beam</label>
                    {/*input figure for singly reinforced force diagram here*/}
                    <LatexCompiler latexInput={String.raw`Figure above depicts the free body diagram of the internal forces that develop in a beam when it is subject to external loading. Here we assume that the applied moment is less than the limiting moment of resistance ($M_{u} <= M_{u,lim}$), resulting in a singly reinforced beam section. The section D-B is considered to be under compression while section B-A is considered cracked and does not contribute to opposing any external applied moment.`} />
                    <p>Now balancing the forces and moments acting on this beam, we get the following two equations:</p>
                    <p><b>Force Balance</b></p>
                    <p>Balancing forces along the X direction:</p>
                    <LatexCompiler latexInput={equations.eq1} alignment="center" />
                    <LatexCompiler latexInput={equations.eq2} alignment="center" />
                    <LatexCompiler latexInput={equations.eq3} alignment="center" />
                    <p><b>Moment Balance</b></p>
                    <p>Balancing moments about point A:</p>
                    <LatexCompiler latexInput={equations.eq4} alignment="center" />
                    <LatexCompiler latexInput={String.raw`where, $M_{u}$ is the applied moment on the beam`} />
                    <LatexCompiler latexInput={String.raw`Now susbstituting these the force balance results into the moment balance equation, we get a quadratic equation in $A_{st}$`} />
                    <LatexCompiler latexInput={equations.eq5} alignment="center" />
                    <LatexCompiler latexInput={String.raw`Solving this equation, we can find the value of required steel reinforcement for the beam.`} />
                    <label className="_articleContentSubHeading" id="singlyReinforcedBeam">3.2. Doubly Reinforced Beam</label>
                    {/*add figure for the forced developed when the beam section is doubly reinforced*/}
                    <LatexCompiler latexInput={String.raw`Figure above depicts the free body diagram of the internal forces that develop in a beam when it is subject to external loading. Here we assume that the applied moment is greater than the limiting moment of resistance ($M_{u} >= M_{u,lim}$), resulting in a doubly reinforced beam section. The section D-B is considered to be under compression while section B-A is considered cracked and does not contribute to opposing any external applied moment.`} />
                    <p>In such a case, the applied moment can be broken down into two parts:</p>
                    <LatexCompiler latexInput={equations.eq6} alignment="center" />
                    <p>Similarly, the required tensile steel can also be broken down into two parts:</p>
                    <LatexCompiler latexInput={equations.eq7} alignment="center" />
                    <LatexCompiler latexInput={String.raw`where, $A_{st,lim}$ is the tensile steel corresponding to $M_{u,lim}$, and $\Delta A_{st}$ is the tensile steel corresponding to $\Delta M_{u}$`} />
                    <LatexCompiler latexInput={String.raw`Now as per the figure shown above, we can calculate $\Delta A_{st}$ as:`} />
                    <LatexCompiler latexInput={equations.eq8} alignment="center" />
                    <p>where d' is the distance of the center of the compression reinforcement to the extrement compression concrete fibre</p>
                    <LatexCompiler latexInput={String.raw`And the compression reinforcement, $A_{sc}$ can be calculated as:`} />
                    <LatexCompiler latexInput={String.raw``} />
                </div>
            </div>
            <Footer textColor="white" />
        </>
    )
}

export default ConcreteBeamISCodeArticle;