// src/components/SignIn.js
import { React, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TitleBar from "./titleBar/v2/titleBar";
import "./profilePage.css";
import { useAuth } from "../authContext";

//feedback content
function Feedback() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        type: "bug report",
        subject: "",
        description: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        axios
            .post("/api/feedback", formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response.ok) {
                    alert(response.data["error"]);
                } else {
                    alert("Failed to send feedback: " + response.data["error"]);
                }
            })
            .catch((error) => {
                console.error("Error sending feedback:", error);
                alert("Failed to send feedback: " + error.message);
            });
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", flex: "1", padding: "20px" }}>
                <p style={{ margin: "0px", fontSize: "20px", fontWeight: "500" }}>Let's Connect!</p>
                <p style={{ marginTop: "10px" }}>We are committed to providing the best experience for our users. Your feedback is invaluable to us as it helps us understand what we're doing right and where we can improve. Whether you have a suggestion, a feature request, or need to report an issue, please take a moment to fill out the form below. Your input will directly contribute to enhancing our services and addressing any concerns you may have. Thank you for helping us serve you better.</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", flex: "1", padding: "20px" }}>
                <div className="feedbackFormLineItem">
                    <label htmlFor="name" class="feedbackFormLabels">
                        Name:
                    </label>
                    <input type="text" id="name" class="feedbackFormInputField" name="name" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="feedbackFormLineItem">
                    <label htmlFor="email" class="feedbackFormLabels">
                        Email:
                    </label>
                    <input type="email" id="email" class="feedbackFormInputField" name="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="feedbackFormLineItem">
                    <label htmlFor="type" class="feedbackFormLabels">
                        Type:
                    </label>
                    <select id="type" class="feedbackFormInputField" name="type" value={formData.type} onChange={handleChange} required>
                        <option value="bug report">Bug Report</option>
                        <option value="feature request">Feature Request</option>
                        <option value="general feedback">General Feedback</option>
                    </select>
                </div>
                <div className="feedbackFormLineItem">
                    <label htmlFor="subject" class="feedbackFormLabels">
                        Subject:
                    </label>
                    <input type="text" id="subject" class="feedbackFormInputField" name="subject" value={formData.subject} onChange={handleChange} required />
                </div>
                <div className="feedbackFormLineItem">
                    <label htmlFor="description" class="feedbackFormLabels">
                        Description:
                    </label>
                    <textarea id="description" name="description" class="feedbackFormTextArea" value={formData.description} onChange={handleChange} required></textarea>
                </div>
                <button className="feedbackFormSubmitButton" onClick={handleSubmit} style={{ width: "150px" }}>
                    Submit
                </button>
            </div>
        </>
    );
}


function Subscription() {
    const { user, loading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // This script adds the Razorpay checkout script to the document
        if (!document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]')) {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.async = true;
            document.body.appendChild(script);
        }

        // Cleanup function to remove the script if needed
        return () => {
            const script = document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    const handlePayment = async () => {
        try {
            const orderUrl = "/api/createOrder";
            const orderData = {
                amount: 10000, // amount in currency subunits
                currency: "INR",
                receipt: "receipt#1",
                token: user.token
            };

            // Create the order when the user clicks the button
            const { data } = await axios.post(orderUrl, orderData);
            console.log(data);

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID || "rzp_live_61RMwyxoG5fvug",
                amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: data.currency,
                name: "Structura Pro", // your business name
                description: "Prod Transaction",
                image: "https://structurapro.co/api/images/companyLogo",
                order_id: data.id, // Order id from razorpay created order
                handler: function (response) {
                    handlePaymentSuccess(response, user, data.amount, data.currency, navigate);
                },
                prefill: {
                    name: user.displayName, // your customer's name
                    email: user.email,
                    contact: user.phoneNumber, // Provide the customer's phone number for better conversion rates
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#3399cc",
                },
            };

            var rzp1 = new window.Razorpay(options);
            rzp1.open();

        } catch (error) {
            console.error("Error creating order", error);
        }
    };

    if (loading) {
        return <p>Loading...</p>; // You can customize the loading state as needed
    }

    const paidUntilDate = new Date(user.paidUntil); // If user.paidUntil is in milliseconds
    const formattedDate = paidUntilDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    return (
        <div className="subscription-container">
            {user && user.paid && user.paidUntil ? (
                <div className="subscription-info">
                    <p>Monthly subscription active, expires on {formattedDate}</p>
                </div>
            ) : (
                <div className="subscription-info">
                    <div className="subscription-info">
                        <p>No subscriptions currently active. </p>
                    </div>
                    <button id="rzp-button1" className="pay-button" onClick={handlePayment}>
                        <span className="strikethrough">Pay ₹150</span>
                        <span className="current-amount">Introductory Offer! Pay ₹100</span> to Subscribe for one month
                    </button>
                    <p className="note">
                        NOTE: New Subscriptions may take up to 30 minutes to reflect on the profile. Please do not pay again if the Payment Succeeded alert pops up. Try refreshing the page to remove stale status.
                    </p>
                </div>
            )}
        </div>
    );
}

function Profile({ name, email, company, title, linkedin }) {
    return (
        <div className="user-profile">
            <div className="user-info">
                <label>Name:</label>
                <span>{name}</span>
            </div>
            <div className="user-info">
                <label>Email:</label>
                <span>{email}</span>
            </div>
        </div>
    );
}

//settings content
const Settings = () => {
    const [emailNotifications, setEmailNotifications] = useState(false);

    const handleToggle = () => {
        setEmailNotifications(!emailNotifications);
    };

    return (
        <div className="settings">
            <div className="setting-item">
                <label>Email Notifications</label>
                <div className="toggle-switch" onClick={handleToggle}>
                    <input type="checkbox" checked={emailNotifications} onChange={handleToggle} />
                    <span className="slider"></span>
                </div>
            </div>
        </div>
    );
};

function ProfilePage() {
    const { user } = useAuth();

    const navigate = useNavigate(); // Use useNavigate hook
    const [activeItem, setActiveItem] = useState("profile");

    const menuItems = [
        { id: "profile", label: "Profile", content: <Profile name={user.displayName} email={user.email} /> },
        // { id: "settings", label: "Settings", content: <Settings /> },
        // { id: "subscriptions", label: "Subscriptions", content: <Subscription /> },
        { id: "feedback", label: "Feedback", content: <Feedback /> },
    ];

    const handleLogout = () => {
        // Clear the authentication token from localStorage
        localStorage.removeItem("authToken");

        // Clear the authentication cookie
        document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        // Redirect to the sign-in page
        navigate("/signIn");
    };

    return (
        <div>
            <TitleBar />
            <div className="_designToolsHeadingDiv">
                <label style={{ fontSize: "24px", fontWeight: "500" }}>Dashboard </label>
            </div>
            <hr className="_horizontalDividerLine" />
            <div className="_productsDiv">
                <div className="_menuItemsDiv" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                    {menuItems.map((item) => (
                        <button key={item.id} className={`menu-item ${activeItem === item.id ? "active" : ""}`} onClick={() => setActiveItem(item.id)}>
                            {item.label}
                        </button>
                    ))}
                    <button className="profilePageButtons" style={{ color: "red" }} onClick={handleLogout}>
                        Logout
                    </button>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", flex: "1" }}>
                    {menuItems.map((item) => (
                        <div key={item.id} className={`content-item ${activeItem === item.id ? "active" : ""}`}>
                            {item.content}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const handlePaymentSuccess = async (response, user, amount, currency, navigate) => {
    console.log("Payment response received", response);

    try {
        const res = await axios.post(
            "/verifyPayment", // Your backend verification endpoint
            {
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
                signature: response.razorpay_signature,
            },
            {
                withCredentials: true,
            }
        );

        if (res.data.success) {
            console.log("Payment successful and verified", res.data);
            try {
                const token = user.token;

                const paymentData = {
                    token: token.token,
                    paymentId: response.razorpay_payment_id,
                    orderId: response.razorpay_order_id,
                    amount: amount,
                    currency: currency,
                };

                const res = await axios.post("/upgradeUserToPaid", paymentData, {
                    withCredentials: true,
                });
                const statusCode = res.status;

                if (statusCode == 200) {
                    console.log("User upgraded to paid successfully");
                    alert("Upgrade Successful. Please sign in again to access the paid features");
                    // the user needs to login again since the access token needs to be refreshed to reflect paid status
                    handleLogout();
                    navigate("/signIn");
                } else {
                    console.error("Failed to upgrade user to paid");
                }

                // Update the token in cookies to avoid re-signing in again
            } catch (error) {
                console.error("Payment verified but error in updating user status:", error);
            }
        } else {
            console.error("Payment verification failed", res.data);
            // Handle the failure case, maybe show an error message to the user
        }
    } catch (error) {
        console.error("Error verifying payment", error);
    }


};

const handleLogout = () => {
    // Clear the authentication token from localStorage
    console.log("logging out");
    localStorage.removeItem("authToken");

    // Clear the authentication cookie
    document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export default ProfilePage;
