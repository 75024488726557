import "./designPagesStyles.css";
import Buttons from "../../components/buttons/buttons";
import React, { useRef, useState, useEffect, useReducer } from "react";
import axios from "axios";
import SettingsMenu from "../../components/settingsMenu/settingsMenu";
import InputSheet from "../../components/inputSheet/inputSheet";
import BeamDesignPDF from "../../components/pdf/beamDesignPDF";
import { pdf } from "@react-pdf/renderer";
import InformationOverlay from "../../components/informationOverlay/informationOverlay";
import CalculationSheet from "../../components/calculationSheet/calculationSheet";
import AppliedForcesAndDesignCapacities from "../../components/tables/appliedForcesAndDesignCapacities/appliedForcesAndDesignCapacitites";
import RectangularBeamLoadingDiagram from "../../components/diagrams/rectangularBeamLoadingDiagram";
import RectangularLongitudinalDiagram from "../../components/diagrams/rectangularLongitudinalDiagram";
import DesignCapacityRatios from "../../components/tables/designCapacityRatios/designCapacityRatios";
import CicrularHollowSectionDiagram from "../../components/diagrams/circularHollowSectionDiagram";
import CostAndQuantity from "../../components/tables/costAndQuantity/costAndQuantity";

//updateForModularity: change the name of the function object that is going to be returned depending on the design sheet
function CircularHollowSteelBeam() {
    //****************************************INPUT REFS AND INPUT VARIABLES****************************************
    //updateForModularity: refs for the inputs that you want to take from the user
    //Ref Variables
    const inputDataRefs = {
        radiusRef: useRef(null),
        lengthRef: useRef(null),
        thicknessRef: useRef(null),
        densityOfSteelRef: useRef(null),
        yieldStrengthOfSteelRef: useRef(null),
        modulusOfElasticityOfSteelRef: useRef(null),
        axialForceRef: useRef(null),
        loadTypeAlongXRef: useRef(null),
        loadAlongXRef: useRef(null),
        loadTypeAlongYRef: useRef(null),
        loadAlongYRef: useRef(null),
        endConditionRef: useRef(null),
        sectionTypeRef: useRef(null),
        effectiveLengthFactorRef: useRef(null),
        unitCostOfSteelRef: useRef(null)
    };

    //Input Variables
    const inputData = {
        //geometric properties
        radius: ["Geometric Properties", "Radius", ["number", inputDataRefs.radiusRef, 600], "mm"],
        length: ["Geometric Properties", "Length", ["number", inputDataRefs.lengthRef, 2000], "mm"],
        thickness: ["Geometric Properties", "Thickness", ["number", inputDataRefs.thicknessRef, 10], "mm"],
        //material properties
        densityOfSteel: ["Material Properties", "Density of Steel", ["number", inputDataRefs.densityOfSteelRef, 7850], "kg/m³"],
        yieldStrengthOfSteel: ["Material Properties", "Yield Strength of Steel", ["number", inputDataRefs.yieldStrengthOfSteelRef, 455], "MPa"],
        modulusOfElasticityOfSteel: ["Material Properties", "Modulus of Elasticity", ["number", inputDataRefs.modulusOfElasticityOfSteelRef, 20000], "MPa"],
        //applied forces
        axialForce: ["Applied Forces", "Axial Force", ["number", inputDataRefs.axialForceRef, 600], "kN"],
        loadTypeAlongX: ["Applied Forces", "Load Type Along X", ["dropdown", inputDataRefs.loadTypeAlongXRef, ["Uniform Line Load", "Point Load"]], ""],
        loadAlongX: ["Applied Forces", "Load Along X", ["number", inputDataRefs.loadAlongXRef, 20], inputDataRefs.loadTypeAlongXRef.current ? (inputDataRefs.loadTypeAlongXRef.current.value == "Uniform Line Load" ? "kN/m" : "N") : ""],
        loadTypeAlongY: ["Applied Forces", "Load Type Along Y", ["dropdown", inputDataRefs.loadTypeAlongYRef, ["Uniform Line Load", "Point Load"]], ""],
        loadAlongY: ["Applied Forces", "Load Along Y", ["number", inputDataRefs.loadAlongYRef, 15], inputDataRefs.loadTypeAlongYRef.current ? (inputDataRefs.loadTypeAlongYRef.current.value == "Uniform Line Load" ? "kN/m" : "N") : ""],
        //setup information
        endCondition: ["Setup Information", "End Condition", ["dropdown", inputDataRefs.endConditionRef, ["Fix-Fix", "Fix-Pin", "Pin-Pin", "Fix-Free"]], ""],
        sectionType: ["Setup Information", "Section Type", ["dropdown", inputDataRefs.sectionTypeRef, ["Hot Rolled", "Cold Formed"]], ""],
        effectiveLengthFactor: ["Setup Information", "Effective Length Factor", ["number", inputDataRefs.effectiveLengthFactorRef, 1], ""],
        //cost information
        unitCostOfSteel: ["Cost Information", "Unit Cost of Steel", ["number", inputDataRefs.unitCostOfSteelRef, 70], "INR/kg"]
    };

    ///****************************************DIAGRAMS****************************************
    //selected diagaram type state variable
    const [selectedDiagramType, setSelectedDiagramType] = useState("sectionDiagram"); //which diagram has been selected in the diagram section
    //diagram div state variables
    const diagramDivRef = useRef(null); // ref for the div containing the diagram
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); //dimensions of the div containing the diagram

    //resizing the diagram when the screen size changes
    useEffect(() => {
        const observeTarget = diagramDivRef.current;
        const resizeObserver = new ResizeObserver((entries) => {
            // Assuming you want to observe the first entry
            const { width, height } = entries[0].contentRect;
            setDimensions({ width, height });
        });
        // Step 2: Initialize the ResizeObserver
        if (observeTarget) {
            resizeObserver.observe(observeTarget);
        }

        // Step 3: Clean up
        return () => {
            if (observeTarget) {
                resizeObserver.disconnect();
            }
        };
    }, []); // Empty dependency array ensures this effect runs only once

    //handle dropdown change for selecting diagram
    const handleDiagramDropdownChange = (event) => {
        setSelectedDiagramType(event.target.value);
    };

    ///****************************************DESIGN RESULTS****************************************
    const [selectedDesignResult, setSelectedDesignResult] = useState("designCapacityRatios");

    //handle the change in selected design result
    const handleDesignResultsDropdownChange = (event) => {
        setSelectedDesignResult(event.target.value);
    }

    //****************************************CALCULATE BUTTON****************************************
    //calculations related state variables
    const [designStatusMessage, setDesignStatusMessage] = useState("Status: Click calculate to design section.")
    const [designCalculationsVisibility, setDesignCalculationsVisibility] = useState(false); //whether calculations are shown or not
    const [designCalculations, setDesignCalculations] = useState({}); //to store the calcualation data we get from back end
    const [appliedForcesAndCapacities, setAppliedForcesAndCapacities] = useState({}); //applied forces and capacities state variables
    const [designCapacityRatios, setDesignCapacityRatios] = useState({}); //design capacity ratios object to generate design capacity ratios table
    const [designSummaryForPDF, setDesignSummaryForPDF] = useState({}) //design summary for pdf
    const [costAndQuantity, setCostAndQuantity] = useState({}); //cost and quantity

    //handle the clickdown of calculate button
    const calculateButtonClicked = async () => {
        //set design status message
        setDesignStatusMessage("Status: Design beam section.");
        //Create request object
        const requestObject = {};
        Object.keys(inputDataRefs).forEach((key) => {
            const keyName = key.replace(/Ref$/, ""); //Removing the "Ref" suffix from each of the key name
            requestObject[`${keyName}`] = inputDataRefs[key].current.value;
        });

        //Send data to back end
        //updateForModularity: change name of fetch request depending on the design sheet
        axios
            .post("/api/fetchCircularHollowSteelBeamDesignCalculations", requestObject, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setDesignCalculations(response.data["designCalculations"]); //Set design calculations state variable
                setAppliedForcesAndCapacities(response.data["appliedForcesAndCapacities"]); //set the applied forces and capacitites
                setDesignCapacityRatios(response.data["designCapacityRatios"]); //Set design capacity ratios to generate table
                setCostAndQuantity(response.data["costAndQuantity"]);
                setDesignSummaryForPDF(response.data["designSummaryForPDF"]);   //set the design summary that has to be printed on the last page of the pdf

                //set the state variables that we have to change things on the front end
                setDesignCalculationsVisibility(true); //Set design calculations visibility
                setDesignStatusMessage("Status: Section design complete.")
            })
            .catch((error) => {
                console.error(`The request could not be completed beacause of ${error}`);
            });
    };

    //****************************************SETTINGS BUTTON****************************************
    //settings menu state variables
    const [displaySettingsMenu, setDisplaySettingsMenu] = useState(false); //toggle to show or not show the settings menu
    const [designedBy, setDesignedBy] = useState("John Doe"); //who has design the structural element (this is going to appear in the final PDF that we are going to print out)
    const [checkedBy, setCheckedBy] = useState("Alice Alex"); //who checked the design calculations (this is going to appear in the final PDF that we are going to print out)
    const [allowableDCR, setAllowableDCR] = useState(1); //what is the DCR that is allowed?

    //hangle clickdown of settings button
    const settingsButtonClicked = () => {
        setDisplaySettingsMenu(true);
    };

    //****************************************PRINT BUTTON****************************************
    //handle clickdown of print pdf button
    const printButtonClicked = async () => {
        if (designCalculationsVisibility) {
            //take current values from all input fields and prepare input data for PDF
            const inputDataForPDF = {};
            Object.keys(inputDataRefs).forEach((key) => {
                const keyName = key.replace(/Ref$/, "");
                inputDataForPDF[`${keyName}`] = [inputData[keyName][0], inputData[keyName][1], inputDataRefs[key].current.value, inputData[keyName][3]];
            });

            //get the svg image that we have to convert (updateForModularity: new ids that have to printed out in the excel sheet)
            //updateForModularity: make a list of all the diagram that you have in the sheet
            const sectionDiagram = await convertSvgToPng("sectionDiagramForPDF", 300, 500);
            const longitudinalDiagram = await convertSvgToPng("longitudinalDiagramForPDF", 300, 500)
            const loadingDiagramAlongX = await convertSvgToPng("loadingDiagramAlongXForPDF", 300, 500)
            const loadingDiagramAlongY = await convertSvgToPng("loadingDiagramAlongYForPDF", 300, 500)

            //updateForModularity: object for all the diagrams in the sheet
            const diagrams = {
                figure1: ["Figure 1: Section Diagram", sectionDiagram],
                figure2: ["Figure 2: Longitudinal Diagram", longitudinalDiagram],
                figure3: ["Figure 3: Loading Diagram along X", loadingDiagramAlongX],
                figure4: ["Figure 4: Loading Diagram along Y", loadingDiagramAlongY]
            };

            //generate pdf from blob
            const blob = await pdf(
                <BeamDesignPDF header={"Circular Hollow Steel Beam Design"}
                    inputDataForPDF={inputDataForPDF} //current inputs that we have from the user
                    calculationDataForPDF={designCalculations} //calculations data that we get from the backend
                    designSummaryForPDF={designSummaryForPDF} //design capacity ratios
                    diagrams={diagrams}
                    designedBy={designedBy}
                    checkedBy={checkedBy}
                />,
            ).toBlob();

            //create url to pdf blob
            const url = URL.createObjectURL(blob);

            //open pdf in new window
            window.open(url, "_blank");
        } else {
            alert(`Please design element by clicking "Calculate" before printing out the design sheet.`);
        }
    };

    //****************************************FEEDBACK BUTTON****************************************
    const feedbackButtonClicked = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSfdBBJP4jNDOLV_ZjkIsvkNhsrcCc4A3xaCU7TyLmN_aunQyQ/viewform?usp=sf_link", "_blank");
    }


    //****************************************INFORMATION BUTTON****************************************
    //handle the clickdown of information button
    const informationButtonClicked = () => {
        window.open("../assets/rectangularHollowSteelBeam/designManual/rectangularHollowSteelBeamDesignManual.pdf", "_blank");
    };

    //****************************************INFORMATION OVERLAYS****************************************
    //information overlay state variables
    const [geometryInformationOverlayVisibility, setGeometryInformationOverlayVisibility] = useState(false);
    const [materialPropertiesInformationOverlayVisibility, setMaterialPropertiesInformationOverlayVisibility] = useState(false);
    const [appliedForcesInformationOverlayVisibility, setAppliedForcesInformationOverlayVisibility] = useState(false);
    const [setupInformationOverlayVisibility, setSetupInformationOverlayVisibility] = useState(false);

    //information overlay functions
    const geometryInformationButtonClicked = () => {
        setGeometryInformationOverlayVisibility(true);
    };

    const materialPropertiesInformationButtonClicked = () => {
        setMaterialPropertiesInformationOverlayVisibility(true);
    };

    const appliedForcesInformationButtonClicked = () => {
        setAppliedForcesInformationOverlayVisibility(true);
    };

    const setupInformationButtonClicked = () => {
        setSetupInformationOverlayVisibility(true);
    };

    const closeInformationOverlays = () => {
        setGeometryInformationOverlayVisibility(false);
        setMaterialPropertiesInformationOverlayVisibility(false);
        setAppliedForcesInformationOverlayVisibility(false);
        setSetupInformationOverlayVisibility(false);
    };

    const informationOverlayFunctions = [geometryInformationButtonClicked, materialPropertiesInformationButtonClicked, appliedForcesInformationButtonClicked, setupInformationButtonClicked];

    //****************************************TITLE BAR BUTTONS****************************************
    //title bar buttons
    const titleBarButtons = {
        calculateButton: ["Calculate", calculateButtonClicked],
        settingsButton: ["Settings", settingsButtonClicked],
        printButton: ["Print", printButtonClicked],
        informationButton: ["Information", informationButtonClicked],
        feebdbackButton: ["Feedback", feedbackButtonClicked]
    };

    const renderedTitleBarButtons = [];
    Object.keys(titleBarButtons).forEach((key) => {
        renderedTitleBarButtons.push(<Buttons type="textButton" text={titleBarButtons[key][0]} fontColor="black" backgroundColor="" linkTo="" onClickFunction={titleBarButtons[key][1]} />);
    });

    return (
        <>
            <div className="_containerDiv">
                {displaySettingsMenu && (
                    <SettingsMenu setDisplaySettingsMenuFunction={setDisplaySettingsMenu}
                        designedBy={designedBy}
                        setDesignedBy={setDesignedBy}
                        checkedBy={checkedBy}
                        setCheckedBy={setCheckedBy}
                        allowableDCR={allowableDCR}
                        setAllowableDCR={setAllowableDCR} />
                )}
                {geometryInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Geometric Properties"
                        informationOverlayCaption={["Geometric Properties: Section View", "Geometric Properties: Longitudinal View"]}
                        informationOverlayImageSrc={["..\\assets\\circularHollowSteelBeam\\geometricPropertiesInformationOverlay_1.png", "..\\assets\\circularHollowSteelBeam\\geometricPropertiesInformationOverlay_2.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {materialPropertiesInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Material Properties"
                        informationOverlayCaption={["Circular Hollow Steel Section: Material Properties"]}
                        informationOverlayImageSrc={["..\\assets\\circularHollowSteelBeam\\materialPropertiesInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {appliedForcesInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Applied Forces"
                        informationOverlayCaption={["Circular Hollow Steel Section: Applied Forces (Uniform Line Load and Point Load"]}
                        informationOverlayImageSrc={["..\\assets\\rectangularHollowSteelBeam\\appliedForcesInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                {setupInformationOverlayVisibility && (
                    <InformationOverlay informationOverlayHeading="Setup Information"
                        informationOverlayCaption={["Circular Hollow Steel Section: Effective Length Factors from IS800:2007 (Table 11)"]}
                        informationOverlayImageSrc={["..\\assets\\circularHollowSteelBeam\\setupInformationInformationOverlay.png"]}
                        closeInformationOverlays={closeInformationOverlays} />
                )}
                <div className="_designInputDiv">
                    <div className="_designInputsTitleBar">
                        <label className="_dashboardHeading">Design Inputs</label>
                    </div>
                    <div className="_designInputs">
                        <InputSheet inputData={inputData} updateCalculationsFunction={calculateButtonClicked} informationOverlayFunctions={informationOverlayFunctions} />
                    </div>
                </div>
                <div className="_designDashboardDiv">
                    <div className="_dashboardTitleBar">
                        <label className="_dashboardHeading" style={{ color: "black" }}>
                            Circular Hollow Steel Beam
                        </label>
                        <div className="_dashboardButtons">{renderedTitleBarButtons}</div>
                    </div>
                    <div className="_dashboardTiles">
                        <div id="_dashboardLeftDiv" className="_dashboardSection">
                            <div id="dashboardTopLeftDiv" className="_dashboardTile">
                                <div className="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>Diagrams</label>
                                    <select className="_dashboardTileTitleBarDropdown" value={selectedDiagramType} onChange={handleDiagramDropdownChange}>
                                        <option value="sectionDiagram">Section Diagram</option>
                                        <option value="longitudinalDiagram">Longitudinal Diagram</option>
                                        <option value="loadingDiagramAlongX">Loading Diagram Along X</option>
                                        <option value="loadingDiagramAlongY">Loading Diagram Along Y</option>
                                    </select>
                                </div>
                                <div className="_tileContentDiv" ref={diagramDivRef}>
                                    <svg className="diagramSVG" id="sectionDiagram" style={{ display: selectedDiagramType == "sectionDiagram" ? "block" : "none" }}>
                                        <CicrularHollowSectionDiagram frameHeight={dimensions.height}
                                            frameWidth={dimensions.width}
                                            radius={inputDataRefs.radiusRef.current ? inputDataRefs.radiusRef.current.value : ""}
                                            thickness={inputDataRefs.thicknessRef.current ? inputDataRefs.thicknessRef.current.value : ""}
                                            stroke="black"
                                            fill="#F5F5F5" />
                                    </svg>
                                    <svg className="diagramSVG" id="longitudinalDiagram" style={{ display: selectedDiagramType == "longitudinalDiagram" ? "block" : "none" }}>
                                        <RectangularLongitudinalDiagram frameHeight={dimensions.height}
                                            frameWidth={dimensions.width}
                                            endCondition={inputDataRefs.endConditionRef.current ? inputDataRefs.endConditionRef.current.value : ""} length={inputDataRefs.lengthRef.current ? inputDataRefs.lengthRef.current.value : ""}
                                            height={inputDataRefs.radiusRef.current ? inputDataRefs.radiusRef.current.value : ""} />
                                    </svg>
                                    <svg className="diagramSVG" id="loadingDiagramAlongX" style={{ display: selectedDiagramType == "loadingDiagramAlongX" ? "block" : "none" }}>
                                        <RectangularBeamLoadingDiagram frameHeight={dimensions.height}
                                            frameWidth={dimensions.width}
                                            endCondition={inputDataRefs.endConditionRef.current ? inputDataRefs.endConditionRef.current.value : ""} loadType={inputDataRefs.loadTypeAlongXRef.current ? inputDataRefs.loadTypeAlongXRef.current.value : ""} load={inputDataRefs.loadAlongXRef.current ? inputDataRefs.loadAlongXRef.current.value : ""} />
                                    </svg>
                                    <svg className="diagramSVG" id="loadingDiagramAlongY" style={{ display: selectedDiagramType == "loadingDiagramAlongY" ? "block" : "none" }}>
                                        <RectangularBeamLoadingDiagram frameHeight={dimensions.height} frameWidth={dimensions.width} endCondition={inputDataRefs.endConditionRef.current ? inputDataRefs.endConditionRef.current.value : ""} loadType={inputDataRefs.loadTypeAlongYRef.current ? inputDataRefs.loadTypeAlongYRef.current.value : ""} load={inputDataRefs.loadAlongYRef.current ? inputDataRefs.loadAlongYRef.current.value : ""} />
                                    </svg>
                                    <svg className="diagramSVG" id="sectionDiagramForPDF" style={{ display: "none" }}>
                                        <CicrularHollowSectionDiagram frameHeight={300}
                                            frameWidth={500}
                                            radius={inputDataRefs.radiusRef.current ? inputDataRefs.radiusRef.current.value : ""}
                                            thickness={inputDataRefs.thicknessRef.current ? inputDataRefs.thicknessRef.current.value : ""}
                                            stroke="black"
                                            fill="#F5F5F5" />
                                    </svg>
                                    <svg className="diagramSVG" id="longitudinalDiagramForPDF" style={{ display: "none" }}>
                                        <RectangularLongitudinalDiagram frameHeight={300}
                                            frameWidth={500}
                                            endCondition={inputDataRefs.endConditionRef.current ? inputDataRefs.endConditionRef.current.value : ""} length={inputDataRefs.lengthRef.current ? inputDataRefs.lengthRef.current.value : ""}
                                            height={inputDataRefs.radiusRef.current ? inputDataRefs.radiusRef.current.value : ""} />
                                    </svg>
                                    <svg className="diagramSVG" id="loadingDiagramAlongXForPDF" style={{ display: "none" }}>
                                        <RectangularBeamLoadingDiagram frameHeight={300}
                                            frameWidth={500}
                                            endCondition={inputDataRefs.endConditionRef.current ? inputDataRefs.endConditionRef.current.value : ""} loadType={inputDataRefs.loadTypeAlongXRef.current ? inputDataRefs.loadTypeAlongXRef.current.value : ""} load={inputDataRefs.loadAlongXRef.current ? inputDataRefs.loadAlongXRef.current.value : ""} />
                                    </svg>
                                    <svg className="diagramSVG" id="loadingDiagramAlongYForPDF" style={{ display: "none" }}>
                                        <RectangularBeamLoadingDiagram frameHeight={300}
                                            frameWidth={500}
                                            endCondition={inputDataRefs.endConditionRef.current ? inputDataRefs.endConditionRef.current.value : ""} loadType={inputDataRefs.loadTypeAlongYRef.current ? inputDataRefs.loadTypeAlongYRef.current.value : ""} load={inputDataRefs.loadAlongYRef.current ? inputDataRefs.loadAlongYRef.current.value : ""} />
                                    </svg>
                                </div>
                            </div>
                            <div id="dashboardBottomLeftDiv" className="_dashboardTile">
                                <label className="_tileHeading">Cost and Quantity</label>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show cost and quantity results.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <CostAndQuantity heading={["Description", "Value"]}
                                            data={costAndQuantity} />)
                                    }
                                </div>
                            </div>
                        </div>
                        <div id="_dashBoardRightDiv" className="_dashboardSection">
                            <div id="dashboardTopRightDiv" className="_dashboardTile">
                                <label className="_tileHeading">Design Calculations</label>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show design calculations.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <CalculationSheet calculationData={designCalculations} />)
                                    }
                                </div>
                            </div>
                            <div id="dashboardBottomRightDiv" className="_dashboardTile">
                                <div className="_dashboardTileTitleBar">
                                    <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>Design Results</label>
                                    <select className="_dashboardTileTitleBarDropdown" value={selectedDesignResult} onChange={handleDesignResultsDropdownChange}>
                                        <option value="designCapacityRatios">Design Capacity Ratios</option>
                                        <option value="appliedForcesAndCapacities">Applied Forces and Capacities</option>
                                    </select>
                                </div>
                                <div class="_tileContentDiv" style={{ fontSize: "14px" }}>
                                    {!designCalculationsVisibility && (
                                        <p style={{ margin: "0px", padding: "0px" }}>Press "Calculate" or change design inputs to show design results.</p>
                                    )}
                                    {designCalculationsVisibility && (
                                        <>
                                            <div style={{ display: selectedDesignResult == "designCapacityRatios" ? "block" : "none" }}>
                                                <DesignCapacityRatios heading={["Description", "Capacity Ratio"]}
                                                    data={designCapacityRatios}
                                                    allowableDCR={allowableDCR} />
                                            </div>
                                            <div style={{ display: selectedDesignResult == "appliedForcesAndCapacities" ? "block" : "none" }}>
                                                <AppliedForcesAndDesignCapacities heading={["Description", "Applied Forces", "Capacity"]}
                                                    data={appliedForcesAndCapacities} />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="_dashboardStatusBar">
                        <label>{designStatusMessage}</label>
                        <label>Structura Pro</label>
                    </div>
                </div>
            </div>
        </>
    );
}

// Function to convert SVG to PNG
async function convertSvgToPng(svgID, height, width) {
    const svgElement = document.getElementById(svgID);
    const svgData = new XMLSerializer().serializeToString(svgElement);

    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const image = new Image(); // Use the standard Image constructor

        image.onload = function () {
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0);

            // Convert canvas to data URL
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
        };

        image.onerror = function (error) {
            reject(error);
        };

        image.src = "data:image/svg+xml;base64," + btoa(svgData);
    });
}

export default CircularHollowSteelBeam;
