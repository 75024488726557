import Rectangle from "./elements/rectangle";
import Circle from "./elements/circle";

function RectangularConcreteColumnReinforcementDiagram({ frameHeight, frameWidth, dX, dY, reinforcementSet }) {
    const frameCenterX = frameWidth / 2;
    const frameCenterY = frameHeight / 2;
    const scalingFactor = dX > 600 || dY > 600 ? 0.2 : 0.3;
    const displayHeight = dY * scalingFactor;
    const displayWidth = dX * scalingFactor;

    const renderedReinforcements = [];
    for (let i = 0; i < reinforcementSet[8].length; i++) {
        renderedReinforcements.push(<text x={frameCenterX + displayWidth / 2 + 10}
            y={frameCenterY + scalingFactor * reinforcementSet[8][i]}
            textAnchor="start"
            dominantBaseline="middle"
            fill="black">{`${reinforcementSet[0][i]}`}</text>)

        for (let j = 0; j < reinforcementSet[6].length; j++) {
            renderedReinforcements.push(<Circle originX={frameCenterX + scalingFactor * reinforcementSet[6][j]}
                originY={frameCenterY + scalingFactor * reinforcementSet[8][i]}
                radius={scalingFactor * reinforcementSet[0][i][j] / 2}
                fill="darkgrey"
                stroke="darkgrey" />)
        }
    }

    return (
        <>
            <Rectangle originX={frameCenterX}
                originY={frameCenterY}
                width={displayWidth}
                height={displayHeight}
                stroke="black"
                fill="#F5F5F5" />
            {renderedReinforcements}
        </>
    );
}

export default RectangularConcreteColumnReinforcementDiagram;