import Rectangle from "./elements/rectangle";
import DottedLine from "./elements/dottedLine";
import Legend from "./elements/legend";

function RectangularSolidSectionLoadingDiagram({ frameHeight, frameWidth, momentAboutX, momentAboutY, height, width }) {
    const frameCenterX = frameWidth / 2;
    const frameCenterY = frameHeight / 2;
    const aspectRatio = width / height;
    const displayHeight = (frameHeight) * 0.25;
    const displayWidth = displayHeight * aspectRatio;
    const dottedLineOffset = 20;
    const legend = {
        mx: ["blue", `Mx: ${momentAboutX} kN-m`],
        my: ["blue", `My: ${momentAboutY} kN-m`],
    };

    return (
        <>
            {/*outer rectangle*/}
            <Rectangle originX={frameCenterX}
                originY={frameCenterY}
                width={displayWidth}
                height={displayHeight}
                stroke="black"
                fill="#F5F5F5" />
            {/*vertical dotted line*/}
            <DottedLine originX={frameCenterX}
                originY={frameCenterY}
                length={displayHeight + dottedLineOffset}
                spanDirection="vertical"
                stroke="darkgrey" />
            <text x={frameCenterX}
                y={frameCenterY - displayHeight / 2 - dottedLineOffset / 2 - 15}
                fill="darkgrey"
                dominantBaseline="hanging"
                textAnchor="middle">Y</text>
            {/*horizontal dotted line*/}
            <DottedLine originX={frameCenterX}
                originY={frameCenterY}
                length={displayWidth + dottedLineOffset}
                spanDirection="horizontal"
                stroke="darkgrey" />
            <text x={frameCenterX + displayWidth / 2 + dottedLineOffset / 2 + 15}
                y={frameCenterY}
                fill="darkgrey"
                dominantBaseline="middle"
                textAnchor="middle">X</text>
            {/*mx ellipse*/}
            <path
                d={`M ${frameCenterX + displayWidth / 2 + 20},${frameCenterY + 20}
                A 50,30 0 0 1 ${frameCenterX + displayWidth / 2 + 20},${frameCenterY - 20}`}
                fill="none"
                stroke="blue" />
            <line x1={frameCenterX + displayWidth / 2 + 20}
                y1={frameCenterY + 20}
                x2={frameCenterX + displayWidth / 2 + 20 - 2}
                y2={frameCenterY + 20 - 7}
                stroke="blue" />
            <line x1={frameCenterX + displayWidth / 2 + 20}
                y1={frameCenterY + 20}
                x2={frameCenterX + displayWidth / 2 + 20 - 7}
                y2={frameCenterY + 20 + 1}
                stroke="blue" />
            <text x={frameCenterX + displayWidth / 2 + 20}
                y={frameCenterY - 20 - 5}
                fill="blue"
                dominantBaseline="auto"
                textAnchor="start">{`${momentAboutX} kN-m`}</text>
            {/*my ellipse*/}
            <path
                d={`M ${frameCenterX + 20},${frameCenterY - displayHeight / 2 - 20}
                A 30,50 0 0 1 ${frameCenterX - 20},${frameCenterY - displayHeight / 2 - 20}`}
                fill="none"
                stroke="blue" />
            <line x1={frameCenterX + 20}
                y1={frameCenterY - displayHeight / 2 - 20}
                x2={frameCenterX + 20 - 1}
                y2={frameCenterY - displayHeight / 2 - 20 + 9}
                stroke="blue" />
            <line x1={frameCenterX + 20}
                y1={frameCenterY - displayHeight / 2 - 20}
                x2={frameCenterX + 20 - 8}
                y2={frameCenterY - displayHeight / 2 - 20 + 2}
                stroke="blue" />
            <text x={frameCenterX - 20 - 5}
                y={frameCenterY - displayHeight / 2 - 20}
                fill="blue"
                dominantBaseline="middle"
                textAnchor="end">{`${momentAboutY} kN-m`}</text>
            {/*legend*/}
            <Legend originX={10}
                originY={frameCenterY}
                sideLength={5}
                verticalSpacing={20}
                items={legend} />
        </>
    )
}

export default RectangularSolidSectionLoadingDiagram;