import TitleBar from "../../components/titleBar/v2/titleBar";
import { Link } from "react-router-dom";
import "./articles.css"
import Footer from "../../components/footer/footer";

function Article({ articleHeading, articleDescription, authorAndDate, linkTo }) {
    return (
        <div className="_articleDiv">
            <p className="_articleHeading">{articleHeading}</p>
            <p className="_articleDescription">{articleDescription}</p>
            <p className="_articleDescription">{authorAndDate}</p>
            <Link to={linkTo}>
                <button className="_button">Go To</button>
            </Link>
        </div>
    )
}

const articles = {
    beamAnalysisArticle: ["Beam Analysis", "This article provides a comprehensive guide to beam analysis, focusing on the essential formulas used to determine the response of beams under various loading conditions and end supports.", "Utkarsh Gupta | August 15, 2024", "/articles/beamAnalysisArticle"],
    trussAnalysisArticle: ["Truss Analysis: Stiffness Matrix Method", "The direct stiffness method is a systematic approach to truss analysis. It involves assembling a global stiffness matrix from individual element matrices, allowing engineers to solve for nodal displacements and forces. This method ensures accurate and efficient analysis of truss structures.", "Utkrash Gupta | August 22, 2024", "/articles/trussAnalysisArticle"],
    steelBeamISCode: ["Steel Beam Design: Indian Standard Code (IS800:2007)", "This article provides a concise guideline for designing steel beams according to the methods and formulas specified in the Indian Standard Code IS 800:2007.", "Utkarsh Gupta | August 28, 2024", "/articles/steelBeamISCodeArticle"],
    concreteBeamISCode: ["Concrete Beam Design: Indian Stanford Code (IS456:2000)", "This article provides a concise guideline for designing concrete beams according to the methods and formulas specificed in the Indian Standord Code IS456:2000.", "Utkarsh Gupta | September 8, 2024", "/articles/concreteBeamISCodeArticle"]
}

let renderedArticles = [];
Object.keys(articles).forEach((key) => {
    renderedArticles.push(<Article articleHeading={articles[key][0]} articleDescription={articles[key][1]} authorAndDate={articles[key][2]} linkTo={articles[key][3]} />)
})

function Articles() {

    return (
        <>
            <TitleBar />
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", overflowY: "scroll" }}>
                <div style={{ width: "60%", display: "flex", flexDirection: "column" }}>
                    <label style={{ fontSize: "24px", fontWeight: "500", marginBottom: "20px", padding: "10px", borderBottom: "1px solid black" }}>Articles</label>
                    {renderedArticles}
                </div>
            </div>
        </>
    );
}

export default Articles;
