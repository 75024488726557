import LatexCompiler from "../../components/latexCompiler/latexCompiler";
import TitleBar from "../../components/titleBar/v2/titleBar";
import "./articles.css";
import Footer from "../../components/footer/footer";

function ImageAndCaption({ imageSrc, caption }) {
    return (
        <div style={{ width: "100%", padding: "10px", alignItems: "center", display: "flex", flexDirection: "column" }}>
            <img alt = "" src={imageSrc} style={{ width: "60%", height: "auto", display: "block" }} />
            <label>{caption}</label>
        </div>
    )
}

function BeamAnalysisArticle() {
    const fixFixUDL = {
        displacement: String.raw`$\delta(x)$ : $\frac{wx^2}{24EI}(L-x)^2$`,
        moment: String.raw`$M(x)$ : $\frac{w}{12}(6Lx - L^2 - 6x^2)$`,
        shear: String.raw`$V(x)$ : $w\left(\frac{L}{2}-x\right)$`
    }

    const pinFixUDL = {
        displacement: String.raw`$\delta(x)$ : $\frac{wx}{48EI}(L^3 - 3Lx^2 + 2x^3)$`,
        moment: String.raw`$M(x)$ : $\frac{3wLx}{8} - \frac{wx^2}{2}$`,
        shear: String.raw`$V(x)$ : $\frac{3wL}{8} - wx$`
    }

    const freeFixUDL = {
        displacement: String.raw`$\delta(x)$ : $\frac{wx}{48EI}(L^3 - 3Lx^2 + 2x^3)$`,
        moment: String.raw`$M(x)$ : $\frac{3wLx}{8} - \frac{wx^2}{2}$`,
        shear: String.raw`$V(x)$ : $\frac{3wL}{8} - wx$`
    }

    const pinPinUDL = {
        displacement: String.raw`$\delta(x)$ : $\frac{wx}{24EI}(L^3 - 2Lx^2 + x^3)$`,
        moment: String.raw`$M(x)$ : $\frac{wx}{2}(L-x)$`,
        shear: String.raw`$V(x)$ : $w\left(\frac{L}{2} - x\right)$`
    }

    const pinPinPL = {
        displacement: String.raw`$\delta(x)$ :
            $\begin{cases}
            \frac{Pbx}{6EIL}(L^2 - b^2 - x^2) & \text{if } 0 \leq x \leq a \\\\
            \frac{Pa(L-x)}{6EIL}(2Lx - x^2 - a^2) & \text{if } a \leq x \leq L
            \end{cases}$`,
        moment: String.raw`$M(x)$ :
            $\begin{cases}
            \frac{Pbx}{L} & \text{if } 0 \leq x \leq a \\\\
            \frac{Pa(L-x)}{L} & \text{if } a \leq x \leq L
            \end{cases}$`,
        shear: String.raw`$V(x)$ : 
            $\begin{cases}
            \frac{Pb}{L} & \text{if } 0 \leq x \leq a \\\\
            \frac{-Pa}{L} & \text{if } a \leq x \leq L
            \end{cases}$`
    }

    const freeFixPL = {
        displacement: String.raw`$\delta(x)$ :
            $\begin{cases}
            \frac{Pb^2}{6EI}(3L - 3x - b) & \text{if } 0 \leq x \leq a \\\\
            \frac{P(L-x)^2}{6EI}(3b - L + x) & \text{if } a \leq x \leq L
            \end{cases}$`,
        moment: String.raw`$M(x)$ :
            $\begin{cases}
            0 & \text{if } 0 \leq x \leq a \\\\
            P(x-a) & \text{if } a \leq x \leq L
            \end{cases}$`,
        shear: String.raw`$V(x)$ :
            $\begin{cases}
            0 & \text{if } 0 \leq x \leq a \\\\
            -P & \text{if } a \leq x \leq L
            \end{cases}$`
    }

    const pinFixPL = {
        r1: String.raw`$R_1$ : $\frac{Pb^2}{2L^3}(a+2L)$`,
        r2: String.raw`$R_2$ : $\frac{Pa}{2L^3}(3L^2 - a^2)$`,
        displacement: String.raw`$\delta(x)$ :
            $\begin{cases}
            \frac{Pb^2x}{12EIL^3}(3aL^2 - 2Lx^2 - ax^2) & \text{if } 0 \leq x \leq a \\\\
            \frac{Pa(L-x)^2}{12EIL^3}(3L^2x - a^2x - 2a^2L) & \text{if } a \leq x \leq L
            \end{cases}$`,
        moment: String.raw`$M(x)$ : 
                $\begin{cases}
                R_1x & \text{if } 0 \leq x \leq a \\\\
                R_1x - P(x-a) & \text{if } a \leq x \leq L
                \end{cases}$`,
        shear: String.raw`$V(x)$ :
                $\begin{cases}
                R_1 & \text{if } 0 \leq x \leq a \\\\
                R_1 - P & \text{if } a \leq x \leq L
                \end{cases}$`
    }

    const fixFixPL = {
        r1: String.raw`$R_1$ : $\frac{Pb^2}{L^3}(3a+b)$`,
        r2: String.raw`$R_2$ : $\frac{Pa^2}{L^3}(a+3b)$`,
        displacement: String.raw`$\delta(x)$ :
            $\begin{cases}
            \frac{Pbx}{6EIL}((L^2 - b^2) - x^2) & \text{if } 0 \leq x \leq a \\\\
            \frac{Pa(L-x)}{6EIL}((L^2 - a^2) - (L-x)^2) & \text{if } a \leq x \leq L
            \end{cases}$`,
        moment: String.raw`$M(x)$ :
            $\begin{cases}
            R_1x - \frac{Pab^2}{L^2} & \text{if } 0 \leq x \leq a \\\\
            R_1x - \frac{Pab^2}{L^2} - P(x-a) & \text{if } a \leq x \leq L
            \end{cases}$`,
        shear: String.raw`$V(x)$ :
            $\begin{cases}
            R_1 & \text{if } 0 \leq x \leq a \\\\
            R_1 - P & \text{if } a \leq x \leq L
            \end{cases}$`
    }

    return (
        <>
            <TitleBar />
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", overflowY: "scroll", marginBottom: "10px" }}>
                <div style={{ width: "60%", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "15px", paddingBottom: "10px", width: "100%", borderBottom: "1px solid black" }}>
                        <label className="_articleContentTitle">Beam Analysis</label>
                        <label>Utkarsh Gupta | August 15, 2024</label>
                    </div>
                    <label className="_articleContentHeading">Table of Contents</label>
                    <div className="_listContainer">
                        <ol>
                            <li><a href="#introduction">Introduction</a></li>
                            <li><a href="#analysisAssumptions">Analysis Assumptions</a></li>
                            <li><a href="#analysisEquations">Analysis Equations</a>
                                <ol type="1">
                                    <li><a href="#fixFixUDL">Fix-Fix Beam with Uniformly Distributed Line Load</a></li>
                                    <li><a href="#pinFixUDL">Pin-Fix Beam with Uniformly Distributed Line Load</a></li>
                                    <li><a href="#freeFixUDL">Free-Fix Beam with Uniformly Distributed Line Load</a></li>
                                    <li><a href="#pinPinUDL">Pin-Pin Beam with Uniformly Distributed Line Load</a></li>
                                    <li><a href="#pinPinPL">Pin-Pin Beam with Point Load</a></li>
                                    <li><a href="#freeFixPL">Free-Fix Beam with Point Load</a></li>
                                    <li><a href="#pinFixPL">Pin-Fix Beam with Point Load</a></li>
                                    <li><a href="#fixFixPL">Fix-Fix Beam with Point Load</a></li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <label className="_articleContentHeading" id="introduction">1. Introduction</label>
                    <p>This article lists the formulas that we need for beam analysis under different end conditions and loading conditions.</p>
                    <label className="_articleContentHeading" id="analysisAssumptions">2. Analysis Assumptions</label>
                    <p>The following assumptions have been made in the analysis of the provided beam:
                        <ul>
                            <li>Self Weight of Beam: This analysis does not consider any additional forces that might be acting on the beam due to its self weight.</li>
                            <li>Plane Sections Remain Plane: During bending, we assume that the plane sections of the prismatic beam remain plane even after the beam has deformed due to bending.</li>
                        </ul>
                    </p>
                    <label className="_articleContentHeading" id="analysisEquations">3. Analysis Equations</label>
                    <label className="_articleContentSubHeading" id="fixFixUDL">3.1. Fix-Fix Beam with Uniformly Distributed Line Load</label>
                    <ImageAndCaption imageSrc="../assets/beamAnalysis/images/fixFixUDL.png" caption="Figure 1: Fix-Fix Beam with Uniformly Distributed Line Load" />
                    <LatexCompiler latexInput={fixFixUDL.displacement} />
                    <LatexCompiler latexInput={fixFixUDL.moment} />
                    <LatexCompiler latexInput={fixFixUDL.shear} />
                    <label className="_articleContentSubHeading" id="pinFixUDL">3.2. Pin-Fix Beam with Uniformly Distributed Line Load</label>
                    <div style={{ width: "100%", padding: "5px", alignItems: "center", display: "flex", flexDirection: "column" }}>
                        <img alt = "" src="../assets/beamAnalysis/images/pinFixUDL.png" style={{ width: "60%", height: "auto", display: "block" }} />
                        <label>Figure 2: Pin-Fix Beam with Uniformly Distributed Line Load</label>
                    </div>
                    <LatexCompiler latexInput={pinFixUDL.displacement} />
                    <LatexCompiler latexInput={pinFixUDL.moment} />
                    <LatexCompiler latexInput={pinFixUDL.shear} />
                    <label className="_articleContentSubHeading" id="freeFixUDL">3.3. Free-Fix Beam with Uniformly Distributed Line Load</label>
                    <ImageAndCaption imageSrc="../assets/beamAnalysis/images/freeFixUDL.png" caption="Figure 3: Free-Fix Beam with Uniformly Distributed Line Load" />
                    <LatexCompiler latexInput={freeFixUDL.displacement} />
                    <LatexCompiler latexInput={freeFixUDL.moment} />
                    <LatexCompiler latexInput={freeFixUDL.shear} />
                    <label className="_articleContentSubHeading" id="pinPinUDL">3.4. Pin-Pin Beam with Uniformly Distributed Line Load</label>
                    <ImageAndCaption imageSrc="../assets/beamAnalysis/images/pinPinUDL.png" caption="Figure 4: Pin-Pin Beam with Uniformly Distributed Line Load" />
                    <LatexCompiler latexInput={pinPinUDL.displacement} />
                    <LatexCompiler latexInput={pinPinUDL.moment} />
                    <LatexCompiler latexInput={pinPinUDL.shear} />
                    <label className="_articleContentSubHeading" id="pinPinPL">3.5. Pin-Pin Beam with Point Load</label>
                    <ImageAndCaption imageSrc="../assets/beamAnalysis/images/pinPinPL.png" caption="Figure 5: Pin-Pin Beam with Point Load" />
                    <LatexCompiler latexInput={pinPinPL.displacement} />
                    <LatexCompiler latexInput={pinPinPL.moment} />
                    <LatexCompiler latexInput={pinPinPL.shear} />
                    <label className="_articleContentSubHeading" id="freeFixPL">3.6. Free-Fix Beam with Point Load</label>
                    <ImageAndCaption imageSrc="../assets/beamAnalysis/images/freeFixPL.png" caption="Figure 6: Free-Fix Beam with Point Load" />
                    <LatexCompiler latexInput={freeFixPL.displacement} />
                    <LatexCompiler latexInput={freeFixPL.moment} />
                    <LatexCompiler latexInput={freeFixPL.shear} />
                    <label className="_articleContentSubHeading" id="pinFixPL">3.7. Pin-Fix Beam with Point Load</label>
                    <ImageAndCaption imageSrc="../assets/beamAnalysis/images/pinFixPL.png" caption="Figure 7: Pin-Fix Beam with Point Load" />
                    <LatexCompiler latexInput={pinFixPL.r1} />
                    <LatexCompiler latexInput={pinFixPL.r2} />
                    <LatexCompiler latexInput={pinFixPL.displacement} />
                    <LatexCompiler latexInput={pinFixPL.moment} />
                    <LatexCompiler latexInput={pinFixPL.shear} />
                    <label className="_articleContentSubHeading" id="fixFixPL">3.8. Fix-Fix Beam with Point Load</label>
                    <ImageAndCaption imageSrc="../assets/beamAnalysis/images/fixFixPL.png" caption="Figure 8: Fix-Fix Beam with Point Load" />
                    <LatexCompiler latexInput={fixFixPL.r1} />
                    <LatexCompiler latexInput={fixFixPL.r2} />
                    <LatexCompiler latexInput={fixFixPL.displacement} />
                    <LatexCompiler latexInput={fixFixPL.moment} />
                    <LatexCompiler latexInput={fixFixPL.shear} />
                </div>
            </div>
            <Footer textColor="white" />
        </>
    )
}

export default BeamAnalysisArticle;