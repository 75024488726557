import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useState } from "react";

function ExpandableSection({ title, data, toFixed = 4 }) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange} sx={{ boxShadow: "none" }}>
            <AccordionSummary sx={{ padding: 0, minHeight: "auto" }}>
                <Box sx={{ display: "flex", alignItems: "center", margin: 0 }}>
                    <img
                        alt="->"
                        style={{
                            marginRight: "8px",
                        }}
                        className="_expandableSectionImage"
                        src={`${expanded ? "../assets/downArrow.png" : "../assets/rightArrow.png"}`}
                    />
                    <Typography component="span" className="section-header">
                        {title}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "8px 16px" }}>
                {data.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            marginBottom: "5px",
                            marginTop: "5px",
                            marginLeft: "14px",
                            lineHeight: "1.5",
                        }}
                    >
                        <span style={{ fontSize: "14px" }}>{item.header}</span>
                        <div style={{ flex: "1 1 0%" }}></div>
                        <span style={{ fontSize: "14px" }}>
                            {/* Fix to max 2 decimal places */}
                            {!isNaN(item.value) && Number(item.value).toFixed(toFixed)}
                            {typeof item.value === "string" && item.value}
                            {typeof item.value === "object" && !Array.isArray(item.value) && Object.values(item.value).join(", ")}
                            {" " + item.unit}
                        </span>
                    </div>
                ))}
            </AccordionDetails>
        </Accordion>
    );
}

export default ExpandableSection;
