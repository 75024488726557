import "./costAndQuantity.css";

//style: this is where you can choose whether you want bold or not. you just have to type true or false here
function TwoLineItems({ item1, item2, bold }) {
    return (
        <div className="_twoLineItemDiv" style={{ fontWeight: `${bold == true ? "500" : "400"}` }}>
            <div className="_twoLineItemItem1" style={{ color: "black" }}>
                <label>{item1}</label>
            </div>
            <div className="_twoLineItemItem2" style={{ color: "black" }}>
                <label>{item2}</label>
            </div>
        </div>
    );
}

function CostAndQuantity({ heading, data }) {
    const renderedLineItems = [];
    renderedLineItems.push(<TwoLineItems item1={heading[0]} item2={heading[1]} bold={true} />);
    Object.keys(data).forEach((key) => {
        renderedLineItems.push(<TwoLineItems item1={data[key][0]} item2={data[key][1]} />);
    });

    return (
        <div className="_table3ContainerDiv">{renderedLineItems}</div>
    );
}

export default CostAndQuantity;
