import LatexCompiler from "../../components/latexCompiler/latexCompiler";
import TitleBar from "../../components/titleBar/v2/titleBar";
import "./articles.css";
import Footer from "../../components/footer/footer"


function ImageAndCaption({ imageSrc, caption }) {
    return (
        <div style={{ width: "100%", padding: "10px", alignItems: "center", display: "flex", flexDirection: "column" }}>
            <img alt = "" src={imageSrc} style={{ width: "40%", height: "auto", display: "block" }} />
            <label>{caption}</label>
        </div>
    )
}

function SteelBeamISCodeArticle() {
    const equations = {
        eq1: String.raw`$T_{dg} = A_gf_y/\gamma_{m0}$`,
        eq2: String.raw`$P_d = A_ef_{cd}$`,
        eq3: String.raw`$f_{cd} = \frac{f_y/\gamma_{m0}}{\phi + [\phi^2-\lambda^2]^{0.5}} = \chi f_y/\gamma_{m0} \leq f_y/\gamma_{m0}$`
    }

    return (
        <>
            <TitleBar />
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", overflowY: "scroll", marginBottom: "10px" }}>
                <div style={{ width: "60%", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "15px", paddingBottom: "10px", width: "100%", borderBottom: "1px solid black" }}>
                        <label className="_articleContentTitle">Steel Beam Design Methods: Indian Standard Code (IS800:2007)</label>
                        <label>Utkarsh Gupta | August 28, 2024</label>
                    </div>
                    <label className="_articleContentHeading">Table of Contents</label>
                    <div className="_listContainer">
                        <ol>
                            <li><a href="#introduction">Introduction</a></li>
                            <li><a href="#coordinateSystem">Geometry, Coordinate System, and Sign Conventions</a></li>
                            <li><a href="#designFormulas">Design Formulas and Codal References</a>
                                <ol type="1">
                                    <li><a href="#tensileCapacity">Tensile Capacity (Cl.6.2)</a></li>
                                    <li><a href="#compressionCapactiy">Compression Capacity (Cl.7.1.2)</a></li>
                                    <li><a href="#bendingCapacity">Bending Moment Capacity (Cl.8.2.1.2)</a></li>
                                    <li><a href="#shearCapacity">Shear Force Capacity (Cl.8.4.1)</a></li>
                                    <li><a href="#combinedShearAndBending">Combined Shear and Bending Capacity (Cl.9.2)</a></li>
                                    <li><a href="#combinedAxialAndBending">Combined Axial and Bending Capacity (Cl.9.3.1.1)</a></li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <label className="_articleContentHeading" id="introduction">1. Introduction</label>
                    <p>This article provides a concise guideline for designing steel beams according to the methods and formulas specified in the Indian Standard Code IS 800:2007.</p>
                    <label className="_articleContentHeading" id="coordinateSystem">2. Geometry, Coordinate System, and Sign Conventions</label>
                    For the purpose of explaining the coordinate system and sign conventions that have been used in this article, we are going to take the example of a rectangular hollow steel section. These can be translated to any section under consideration.
                    <ImageAndCaption imageSrc="../assets/rectangularHollowSteelSection/images/geometricPropertiesInformationOverlay_1.png" caption="Figure 1: Section View" />
                    <ImageAndCaption imageSrc="../assets/rectangularHollowSteelSection/images/geometricPropertiesInformationOverlay_2.png" caption="Figure 2: Longitudinal View" />
                    <ImageAndCaption imageSrc="../assets/rectangularHollowSteelSection/images/forcesAppliedInformationOverlay_1.png" caption="Figure 3: Applied Moment and Shear" />
                    <ImageAndCaption imageSrc="../assets/rectangularHollowSteelSection/images/forcesAppliedInformationOverlay_2.png" caption="Figure 4: Applied Axial Load" />
                    <label className="_articleContentHeading" id="designFormulas">3. Design Formulas and Codal References</label>
                    In this section, all mentioned design equations have been cited from IS800:2007 (Indian Standard Code for Design of Steel Structures).
                    <label className="_articleContentSubHeading" id="tensileCapacity">3.1. Tensile Capacity (Cl.6.2)</label>
                    <LatexCompiler latexInput={String.raw`The design tensile strength of members under axial tension, $T_{dg}$ as governed by yielding of the gross cross-section, is given by,`} />
                    <LatexCompiler latexInput={equations.eq1} alignment="center" />
                    where:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`$f_y$: yield stress of material`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$A_g$: gross cross-sectional area`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$\gamma_{m0}$: partial safety factor of failure in tension by yielding`} /></li>
                    </ul>
                    <label className="_articleContentSubHeading" id="compressionCapactiy">3.2. Compression Capacity (Cl.7.1.2)</label>
                    The design compressive strength of a member is given by
                    <LatexCompiler latexInput={equations.eq2} alignment="center" />
                    where:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`$A_{e}$: effective section area`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$f_{cd}$: design compressive stress, obtained as per $\textbf{Cl.7.1.2.1}$`} /></li>
                    </ul>
                    <LatexCompiler latexInput={String.raw`$\textbf{Cl.7.1.2.1:}$ The design compressive stress, $f_{cd}$ of axially loaded compression members shall be calculated using the following equation:`} />
                    <LatexCompiler latexInput={equations.eq3} alignment="center" />
                    where:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`$\phi$: $0.5 [1+\alpha(\lambda - 0.2) + \lambda^2]$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$\lambda$: non-dimensional effective slenderness ratio = $\sqrt{f_y/f_{cc}}$ = $\sqrt{\frac{f_y(KL/r)^2}{\pi^2E}}$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$f_{cc}$: Euler Buckling Stress = $\frac{\pi^2E}{(KL/r)^2}$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$KL/r$: effective slenderness ratio`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$\alpha$: imperfection factor given in Table 7 (IS800:2007)`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$\gamma_{m0}$: partial safety factor for material strength`} /></li>
                    </ul>
                    <label className="_articleContentSubHeading" id="bendingCapacity">3.3. Bending Moment Capacity (Cl.8.2.1.2)</label>
                    <LatexCompiler latexInput={String.raw`When the factored design shear force does not exceed $0.6V_d$, where $V_d$ is the design shear strength of the cross-section, the design bending strength $M_d$ shall be taken as:`} />
                    <LatexCompiler latexInput={String.raw`$M_d = \beta_bZ_pf_y/\gamma_{m0}$`} alignment="center" />
                    <LatexCompiler latexInput={String.raw`To avoid irreversible deformation under serviceability loads, $M_d$ shall be less than $1.2 Z_ef_y/\gamma_{m0}$ in case of simply supported and $1.5 Z_ef_y/\gamma_{m0}$ in cantilever beams.`} />
                    where:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`$\beta_b$: 1.0 for plastic and compact sections`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$\beta_b$: $Z_e/Z_p$ for semi-compact sections`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$Z_p, Z_e$: plastic and elastic section modulii of the cross-section respectively`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$f_y$: yield strength of steel`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$\gamma_{m0}$: partial safety factor`} /></li>
                    </ul>
                    <label className="_articleContentSubHeading" id="shearCapacity">3.4. Shear Force Capacity (Cl.8.4.1)</label>
                    The nominal plastic shear resistance due to pure shear is given by:
                    <LatexCompiler latexInput={String.raw`$V_n = V_p$`} alignment="center" />
                    where:
                    <LatexCompiler latexInput={String.raw`$V_p = \frac{A_vf_{yw}}{\sqrt{3}}$`} alignment="center" />
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`$A_v$: shear area`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$f_{yw}$: yield strength of web`} /></li>
                    </ul>
                    The shear area may be calculated as given below:
                    <b>I and Channel Section:</b>
                    Major Axis Bending:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`Hot Rolled: $ht_w$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`Welded: $dt_w$`} /></li>
                    </ul>
                    Minor Axis Bending:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`Hot Rolled or Welded: $2bt_f$`} /></li>
                    </ul>
                    <b>Rectangular Hollow Sections of Uniform Thickness:</b>
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`Loaded Parallel to Depth: $Ah/(b+h)$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`Loaded Parallel to Width: $Ab/(b+h)$`} /></li>
                    </ul>
                    <b>Circular Hollow Tubes of Uniform Thickness:</b>
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`$2A/\pi$`} /></li>
                    </ul>
                    <b>Plates and Solid Bars:</b>
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`$A$`} /></li>
                    </ul>
                    where:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`A: cross-sectional area`} /></li>
                        <li><LatexCompiler latexInput={String.raw`b: overall breadth of tubular section, breadth of I-section flanges`} /></li>
                        <li><LatexCompiler latexInput={String.raw`d: clear depth of the web between flanges`} /></li>
                        <li><LatexCompiler latexInput={String.raw`h: overall depth of the section`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$t_f$: thickness of the flange`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$t_w$: thickness of the web`} /></li>
                    </ul>
                    <label className="_articleContentSubHeading" id="combinedShearAndBending">3.5. Combined Shear and Bending Capacity (Cl.9.2)</label>
                    <LatexCompiler latexInput={String.raw`$\textbf{Cl.9.2.1:}$ No reduction in moment capacity of the section is necessary as long as the cross-section is not subjected to high shear force (factored value of applied shear force is less than or equal to 60 percent of the shear strength of the section as given in 8.4). The moment capacity may be taken as, $M_d$ (see 8.2) without any reduction.`} />
                    <LatexCompiler latexInput={String.raw`$\textbf{Cl.9.2.2:}$ When the factored value of the applied shear force is high (exceeds the limit specified in 9.2.1), the factored moment of the section should be less than the moment capacity of the section under high shear force, $M_{dv}$, calculated as given below:`} />
                    <b>Plastic or Compact Section</b>
                    <LatexCompiler latexInput={String.raw`$M_{dv} = M_d - \beta(M_d - M_{fd}) \leq 1.2 Z_ef_y/\gamma_{m0}$`} alignment="center" />
                    where:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`$\beta$: $(2V/V_d-1)^2$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$M_d$: plastic design moment of the whole section disregarding high shear force effect`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$V$: factored applied shear force as governed by web yielding or web buckling`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$V_d$: design shear strength as governed by web yielding or web buckling`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$M_{fd}$: plastic design strength of the area of the cross-section excluding the shear area, considering partial safety factor $\gamma_{m0}$`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$Z_e$: elastic section modulus of the whole section`} /></li>
                    </ul>
                    <b>Semi-Compact Section</b>
                    <LatexCompiler latexInput={String.raw`$M_{dv} = Z_ef_y/\gamma_{m0}$`} alignment="center" />
                    <label className="_articleContentSubHeading" id="combinedAxialAndBending">3.5. Combined Axial and Bending Capacity (Cl.9.3.1.1)</label>
                    In the design of members subjected to combined axial force (tension or compression) and bending moment, the following should be satisfied:
                    <LatexCompiler latexInput={String.raw`$\left(\frac{M_y}{M_{ndy}}\right)^{\alpha_1} + \left(\frac{M_z}{M_{ndz}}\right)^{\alpha_2} \leq 1.0$`} alignment="center" />
                    Conservatively, the following equation may also be used under combined axial force and bending moment:
                    <LatexCompiler latexInput={String.raw`$\frac{N}{N_d} + \frac{M_y}{M_{dy}} + \frac{M_z}{M_{dz}} \leq 1.0$`} alignment="center" />
                    where:
                    <ul>
                        <li><LatexCompiler latexInput={String.raw`$M_y, M_z$: factored applied moments about the minor and major axis of the cross section respectively`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$M_{ndy}, M_{ndz}$: design reduced flexural strength under combined axial force and the respective uni-axial moment acting alone`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$N$: factored applied axial force`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$N_d$: design strength in tension, $T_d$ or in compression due to yielding`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$M_{dy}, M_{dz}$: design strength under corresponding moment acting alone`} /></li>
                        <li><LatexCompiler latexInput={String.raw`$\alpha_1, \alpha_2$: constants as given in Table 17 (IS800:2007)`} /></li>
                    </ul>
                </div>
            </div>
            <Footer textColor="white" />
        </>
    )
}

export default SteelBeamISCodeArticle;