import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Line, Text, Arrow } from "react-konva";

const ColumnBucklingDiagram = ({ chartData, L, endCondition, forceTop, forceBottom }) => {
    // chartData: object with arrays: { x: [...], y: [...] } where:
    // - x: positions along the column (0 to L, in mm)
    // - y: corresponding lateral deflections (in mm)
    // L: physical length of the beam (mm)
    // endCondition: one of "Fix-Fix", "Pin-Pin", "Fix-Free"

    const containerRef = useRef(null);
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });

    // Update stage dimensions for responsiveness
    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                const { offsetWidth, offsetHeight } = containerRef.current;
                setStageSize({ width: offsetWidth, height: offsetHeight });
            }
        };

        updateSize();
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    // Wait until stage size and chartData are available
    if (stageSize.width === 0 || stageSize.height === 0 || !chartData || !chartData.x || chartData.x.length === 0) {
        return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
    }

    // Define margins (in pixels)
    const margin = 50;
    // Available vertical drawing area
    const displayHeight = stageSize.height - 2 * margin;
    // Center of stage horizontally
    const centerX = stageSize.width / 2;
    // Scale factor: map physical length (L in mm) to displayHeight (pixels)
    const scale = displayHeight / L;

    // const points = [];
    // for (let i = 0; i < chartData.x.length; i++) {
    //   const physPos = chartData.x[i]; // in mm (vertical position along the beam)
    //   const deflection = chartData.y[i]; // lateral deflection (mm)
    //   const yPixel = margin + physPos * scale;
    //   const xPixel = centerX + deflection * scale;
    //   points.push(xPixel, yPixel);
    // }

    // Draw end-condition indicators based on the endCondition prop.
    let topIndicator = null;
    let bottomIndicator = null;
    if (endCondition === "Fix-Fix") {
        topIndicator = (
            <>
                {[0, 1, 2, 3, 4].map((index) => {
                    return <Line key={index} points={[centerX - 20 + index * 10, margin, centerX - 20 + index * 10 + 5, margin - 8]} stroke="black" strokeWidth={1} />;
                })}
                <Line points={[centerX - 20, margin, centerX + 20, margin]} stroke="black" strokeWidth={2} />
            </>
        );
        bottomIndicator = (
            <>
                {[0, 1, 2, 3, 4].map((index) => {
                    return <Line key={index} points={[centerX - 20 + index * 10, margin + displayHeight, centerX - 20 + index * 10 + 5, margin + displayHeight + 8]} stroke="black" strokeWidth={1} />;
                })}
                <Line points={[centerX - 20, margin + displayHeight, centerX + 20, margin + displayHeight]} stroke="black" strokeWidth={2} />
            </>
        );
    } else if (endCondition === "Pin-Pin") {
        topIndicator = (
            <>
                {[0, 1, 2].map((index) => {
                    return <Line key={index} points={[centerX - 25, margin - 15 + index * 15, centerX - 35, margin - 5 + index * 15]} stroke="black" strokeWidth={1} />;
                })}
                <Line points={[centerX - 25, margin - 15, centerX - 25, margin + 15]} stroke="black" strokeWidth={2} />
                <Line points={[centerX - 25, margin - 5, centerX, margin, centerX - 25, margin + 5]} tension={0.2} stroke="black" strokeWidth={1} />
            </>
        );
        bottomIndicator = (
            <>
                {[0, 1, 2].map((index) => {
                    return <Line key={index} points={[centerX - 25, margin + displayHeight - 15 + index * 15, centerX - 35, margin + displayHeight + index * 15]} stroke="black" strokeWidth={1} />;
                })}
                <Line points={[centerX - 25, margin + displayHeight - 15, centerX - 25, margin + displayHeight + 15]} stroke="black" strokeWidth={2} />
                <Line points={[centerX - 25, margin + displayHeight + 5, centerX, margin + displayHeight, centerX - 25, margin + displayHeight - 5]} tension={0.2} stroke="black" strokeWidth={1} />
            </>
        );
    } else if (endCondition === "Fix-Pin") {
        topIndicator = (
            <>
                {[0, 1, 2].map((index) => {
                    return <Line key={index} points={[centerX - 25, margin - 15 + index * 15, centerX - 35, margin - 5 + index * 15]} stroke="black" strokeWidth={1} />;
                })}
                <Line points={[centerX - 25, margin - 15, centerX - 25, margin + 15]} stroke="black" strokeWidth={2} />
                <Line points={[centerX - 25, margin - 5, centerX, margin, centerX - 25, margin + 5]} tension={0.2} stroke="black" strokeWidth={1} />
            </>
        );
        bottomIndicator = (
            <>
                {[0, 1, 2, 3, 4].map((index) => {
                    return <Line key={index} points={[centerX - 20 + index * 10, margin + displayHeight, centerX - 20 + index * 10 + 5, margin + displayHeight + 8]} stroke="black" strokeWidth={1} />;
                })}
                <Line points={[centerX - 20, margin + displayHeight, centerX + 20, margin + displayHeight]} stroke="black" strokeWidth={2} />
            </>
        );
    } else if (endCondition === "Fix-Free") {
        bottomIndicator = (
            <>
                {[0, 1, 2, 3, 4].map((index) => {
                    return <Line key={index} points={[centerX - 20 + index * 10, margin + displayHeight, centerX - 20 + index * 10 + 5, margin + displayHeight + 8]} stroke="black" strokeWidth={1} />;
                })}
                <Line points={[centerX - 20, margin + displayHeight, centerX + 20, margin + displayHeight]} stroke="black" strokeWidth={2} />
            </>
        );
    }

    return (
        <div ref={containerRef} style={{ width: "100%", height: "100%" }}>
            <Stage width={stageSize.width} height={stageSize.height}>
                <Layer>
                    {/* an arrow showing the direction of the force at the top of the beam */}
                    <Text x={centerX + 10} y={margin - 40} text={forceTop} fontSize={14} fill="black" />
                    <Arrow points={[centerX, margin - 40, centerX, margin - 5]} pointerLength={8} pointerWidth={8} fill="blue" stroke="blue" strokeWidth={2} />
                    {/* Draw the deflected beam (vertical column) */}
                    <Line points={[centerX, margin, centerX, margin + displayHeight]} stroke="black" strokeWidth={2} lineCap="round" lineJoin="round" />
                    {/* End Condition Indicators */}
                    {topIndicator}
                    {bottomIndicator}
                    {/* Dimension Label at the center, rotated at 90 deg */}
                    <Text x={centerX - 120} y={margin + 20 + displayHeight / 2} rotation={270} text={`${L} mm`} fontSize={12} fill="black" />
                    {/* Line along the beam */}
                    <Line points={[centerX - 110, margin, centerX - 90, margin]} stroke="lightgrey" strokeWidth={2} />
                    <Line points={[centerX - 100, margin, centerX - 100, margin + displayHeight]} stroke="lightgrey" strokeWidth={2} lineCap="round" lineJoin="round" />
                    <Line points={[centerX - 110, margin + displayHeight, centerX - 90, margin + displayHeight]} stroke="lightgrey" strokeWidth={2} />
                </Layer>
            </Stage>
        </div>
    );
};

export default ColumnBucklingDiagram;
