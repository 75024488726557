import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(LineElement, PointElement, CategoryScale, Title, Tooltip, Legend);

const BeamAnalysisChart = ({ xAxisData, yAxisData, xAxisLabel, yAxisLabel, width, title, inverse }) => {
    const [chartData, setChartData] = useState(null);
    const [options, setOptions] = useState(null);
    useEffect(() => {
        const abortController = new AbortController();
        const points =
            inverse === true
                ? xAxisData.map((position, index) => ({
                      x: yAxisData[index],
                      y: position,
                  }))
                : xAxisData.map((x, i) => ({ x, y: yAxisData[i] }));

        setChartData({
            datasets: [
                {
                    label: title,
                    data: points,
                    borderColor: "black",
                    borderWidth: 0.5,
                    tension: 0.1,
                    fill: false,
                    pointRadius: 0,
                },
            ],
        });

        setOptions({
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            plugins: {
                legend: {
                    position: "top",
                    labels: { font: { size: 14 } },
                    display: false,
                },
                title: {
                    display: false,
                    text: title,
                    font: { size: 16 },
                },
            },
            scales: {
                x: {
                    min: chartData ? -Math.max(...chartData.datasets[0].data.map((d) => Math.abs(d.x))) - 3 : 0,
                    max: chartData ? Math.max(...chartData.datasets[0].data.map((d) => Math.abs(d.x))) + 3 : 1,
                    type: "linear",
                    title: {
                        display: true,
                        text: inverse === true ? yAxisLabel : xAxisLabel,
                        font: { size: 14 },
                    },
                    ticks: {
                        callback: (value) => Number(value).toFixed(2),
                    },
                },
                y: {
                    max: chartData ? Math.max(...chartData.datasets[0].data.map((d) => d.y)) + 200 : 1,
                    type: "linear",
                    title: {
                        display: true,
                        text: inverse === true ? xAxisLabel : yAxisLabel,
                        font: { size: 14 },
                    },
                    ticks: {
                        callback: (value) => Number(value).toFixed(0),
                        stepSize: 200,
                    },
                },
            },
        });

        return () => abortController.abort();
    }, [xAxisData, yAxisData, title, inverse, chartData, yAxisLabel, xAxisLabel]);

    const customLine = {
        id: "customLine",
        // draw a line vertically on the chart at x = 0
        beforeDraw: (chart) => {
            const ctx = chart.ctx;
            const xAxis = chart.scales.x;
            const yAxis = chart.scales.y;
            const x = xAxis.getPixelForValue(0);
            if (x) {
                ctx.save();
                ctx.beginPath();
                ctx.strokeStyle = "grey";
                // ctx.setLineDash([5, 5]);
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.stroke();
                ctx.restore();
            }
        },
    };

    return (
        <div
            style={{
                width: { width },
                height: "100%",
                position: "relative",
                margin: "0 auto",
            }}
        >
            {chartData ? (
                <Line data={chartData} options={options} plugins={[customLine]} />
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <p>Loading chart data...</p>
                </div>
            )}
        </div>
    );
};

export default BeamAnalysisChart;
