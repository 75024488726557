import "./designTools.css";
import TitleBar from "../../components/titleBar/v2/titleBar";
import ProductTile from "../../components/productTile/productTile";
import { useState } from "react";
import ProductSection from "../../components/productSection/productSection";

function ChoiceLineItem({ checkboxValue, choiceLabelText, onClickFunction }) {
    return (
        <li style={{ marginTop: "15px", marginBottom: "15px" }}>
            <label>
                <input type="checkbox" name="choice" value={checkboxValue} style={{ marginRight: "10px" }} onClick={onClickFunction} />
                {choiceLabelText}
            </label>
        </li>
    );
}

function ChoiceList({ choiceListData, onClickFunction }) {
    const renderedChoiceList = [];
    Object.keys(choiceListData).map((key) => {
        renderedChoiceList.push(<ChoiceLineItem checkboxValue={choiceListData[key][0]} choiceLabelText={choiceListData[key][1]} onClickFunction={onClickFunction} />);
    });

    return <ul style={{ listStyleType: "none", padding: "0px" }}>{renderedChoiceList}</ul>;
}

function DesignTools() {
    const productTiles = {
        beamAnalysis: ["Analysis Tools", "Beam Analysis", "Free", "Given the beam properties, analyse the beam for displacement, moment and shear force diagram.", "/designTools/beamAnalysis"],
        trussAnlaysis: ["Analysis Tools", "Truss Analysis", "Free", "Free draw trusses and analyze them using the global stiffness matrix methods", "/designTools/trussAnalysis"],
        columnBucklingSimulator: ["Analysis Tools", "Column Buckling Simulator", "Free", "Given the column properties, simulate the buckling of the column and find out the critical load", "/designTools/columnBucklingSimulator"],
        mohrsCircle: ["Analysis Tools", "Mohr's Circle", "Free", "Given the stresses acting on a plane, find out the principal stresses and the maximum shear stress using Mohr's Circle", "/designTools/mohrsCircle"],
        rectangularHollowSteelSection: ["Steel Member Design Tools", "Rectangular Hollow Steel Section (Beam)", "Free", "Given moments, shear forces and axial forces on a rectangular hollow steel section, design and check whether the section works or not. Iterate through configuarations to optimise section as per your need.", "/designTools/rectangularHollowSteelSection"],
        circularHollowSteelSection: ["Steel Member Design Tools", "Circular Hollow Steel Section (Beam)", "Free", "Given moments, shear forces and axial forces on a circular hollow steel section, design and check whether the section works or not. Iterate through configuarations to optimise section as per your need.", "/designTools/circularHollowSteelSection"],
        rectangularHollowSteelSectionOptimizer: ["Steel Member Design Tools", "Rectangular Hollow Steel Section Optimizer (Beam)", "Free", "Given the moment, shear and axial force acting on a beam, find out the most optimized section size as per design constraints", "/designTools/rectangularHollowSteelSectionOptimizer"],
        circularHollowSteelSectionOptimizer: ["Steel Member Design Tools", "Circular Hollow Steel Section Optimizer (Beam)", "Free", "Given the moment, shear and axial force acting on a beam, find out the most optimized section size as per design constraints", "/designTools/circularHollowSteelSectionOptimizer"],
        rectangularSolidConcreteColumn: ["Concrete Member Design Tools", "Rectangular Concrete Column", "Free", "Given a rectangular column and applied forces, desing reinforcement as per IS456:2000. Also view PM Interaction curves for validation", "/designTools/rectangularSolidConcreteColumn"],
        rectangularSolidConcreteColumnOptimizer: ["Concrete Member Design Tools", "Rectangular Concrete Column Optimizer", "Free", "Given the forces acting on a column, design it with with the most optimized sizing and reinforcement.", "/designTools/rectangularSolidConcreteColumnOptimizer"],
        // rectangularHollowSteelBeam: ["Steel Member Design Tools", "Rectangular Hollow Steel Beam", "Free", "Given a uniform line load or a point load acting on a rectangular hollow steel beam, design for different end conditions", "/designTools/rectangularHollowSteelBeam"],
        // circularHollowSteelBeam: ["Steel Member Design Tools", "Circular Hollow Steel Beam", "Free", "Given a uniform line load or a point load acting on a rectangular hollow steel beam, design for different end conditions", "/designTools/circularHollowSteelBeam"],
        // rectangularSolidConcreteBeam: ["Concrete Member Design Tools", "Rectangular Solid Concrete Beam", "Free", "Given uniform line load or point load acting on a rectangular concrete beam, analyse and design on the fly. Get reinforcement calculations as well.", "/designTools/rectangularSolidConcreteBeam"],
        // rectangularSolidConcreteBeamOptimizer: ["Concrete Member Optimization Tools", "Rectangular Solid Concrete Beam Optimizer", "Free", "Given the loads acting on a beam, and the maximum width and depth you want to provide, design the most optimized beam section.", "/designTools/rectangularSolidConcreteBeamOptimizer"],
        // rectangularHollowSteelBeamOptimizer: ["Steel Member Optimization Tools", "Rectangular Hollow Steel Beam Optimizer", "Free", "Given forces acting on a beam, find the most cost effective beam section for your design.", "/designTools/rectangularHollowSteelBeamOptimizer"],
        // circularHollowSteelBeamOptimizer: ["Steel Member Optimization Tools", "Circular Hollow Steel Beam Optimizer", "Free", "Given forces acting on a beam, find the most cost effective beam section for your design.", "/designTools/circularHollowSteelBeamOptimizer"],
    };

    //state variables to carry out search
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredProducts, setFilteredProducts] = useState(productTiles);

    // Event handler for handling changes in the search input
    const handleSearchInputChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        filterProducts(query);
    };

    function filterProducts(query) {
        let filtered = {};
        Object.keys(productTiles).forEach((key) => {
            if (productTiles[key][1].toLowerCase().includes(query.toLowerCase())) {
                filtered[key] = productTiles[key];
            }
        });

        setFilteredProducts(filtered);
    }

    //product sections
    let productSections = [];
    Object.keys(filteredProducts).forEach((key) => {
        productSections.push(filteredProducts[key][0]);
    });
    const uniqueProductSections = [...new Set(productSections)];

    let renderedProductTiles = [];
    uniqueProductSections.forEach((section) => {
        const sectionTiles = [];
        Object.keys(filteredProducts).forEach((key) => {
            if (filteredProducts[key][0] === section) {
                sectionTiles.push(<ProductTile productTileHeading={filteredProducts[key][1]} productTileAccessStatus={filteredProducts[key][2]} productTileDescription={filteredProducts[key][3]} productTileLinkPath={filteredProducts[key][4]} />);
            }
        });

        renderedProductTiles.push(<ProductSection heading={section} productTiles={sectionTiles} />);
    });

    // Object.keys(filteredProducts).forEach((key) => {
    //     renderedProductTiles.push(<ProductTile productTileHeading={filteredProducts[key][1]} productTileAccessStatus={filteredProducts[key][2]} productTileDescription={filteredProducts[key][3]} productTileLinkPath={filteredProducts[key][4]} />);
    // });

    //choice list for filters
    const structuralMaterialList = {
        concrete: ["concrete", "Concrete"],
        steel: ["steel", "Steel"],
        timber: ["timber", "Timber"],
    };

    const structuralMembersList = {
        sections: ["section", "Section"],
        beam: ["beam", "Beam"],
        column: ["column", "Column"],
        slab: ["slab", "Slab"],
    };

    document.addEventListener("DOMContentLoaded", (event) => {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');

        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener("change", (event) => {
                handleCheckboxChange(event.target);
            });
        });
    });

    function handleCheckboxChange(checkbox) {
        if (checkbox.checked) {
            // console.log(checkbox.value + " is checked");
            // Add your code to handle the checked state
        } else {
            // console.log(checkbox.value + " is unchecked");
            // Add your code to handle the unchecked state
        }
    }

    function getSelectedChoices() {
        const checkboxes = document.querySelectorAll('input[name="choice"]:checked');
        let selectedValues = [];
        checkboxes.forEach((checkbox) => {
            selectedValues.push(checkbox.value);
        });
        //here i have to add all the values that have been selected into the search query thing
        let selectedValueString = selectedValues.join(" ");
        console.log(selectedValueString);
        filterProducts(selectedValueString);
    }

    return (
        <>
            <TitleBar />
            <div className="_designToolsHeadingDiv">
                <label style={{ fontSize: "24px", fontWeight: "500" }}>Design Tools</label>
                <input className="_productTilesSearchField" type="text" value={searchQuery} onChange={handleSearchInputChange} placeholder="Search for Design Tool" />
            </div>
            <hr className="_horizontalDividerLine" />
            <div className="_productsDiv">
                <div className="_filterDiv">
                    <hr className="_horizontalDividerLine" style={{ marginLeft: "0px", height: "0.25px" }} />
                    <p style={{ fontSize: "16px", fontWeight: "500" }}>Structural Members</p>
                    <ChoiceList choiceListData={structuralMembersList} onClickFunction={getSelectedChoices} />
                    <hr className="_horizontalDividerLine" style={{ marginLeft: "0px", height: "0.25px" }} />
                    <p style={{ fontSize: "16px", fontWeight: "500" }}>Material</p>
                    <ChoiceList choiceListData={structuralMaterialList} onClickFunction={getSelectedChoices} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "80%" }}>{renderedProductTiles}</div>
            </div>
        </>
    );
}

export default DesignTools;
