import Rectangle from "./elements/rectangle";
import DottedLine from "./elements/dottedLine";
import DimensionLine from "./elements/dimensionLine";
import Legend from "./elements/legend";

function RectangularColumnLongitudinalDiagram({ frameHeight, frameWidth, height, width, stroke, fill }) {
    const frameCenterX = frameWidth / 2;
    const frameCenterY = frameHeight / 2;
    const displayHeight = 0.5 * frameHeight;
    const aspectRatio = width / height;
    const displayWidth = displayHeight * aspectRatio;
    const dottedLineExtension = 15;
    const legend = {
        height: ["black", `Height: ${height} mm`],
        width: ["black", `Dx: ${width} mm`]
    }

    return (
        <>
            {/*main rectangle for column*/}
            <Rectangle originX={frameCenterX}
                originY={frameCenterY}
                width={displayWidth}
                height={displayHeight}
                stroke={stroke}
                fill={fill} />
            {/*top dotted line to show supports*/}
            <DottedLine originX={frameCenterX}
                originY={frameCenterY - displayHeight / 2}
                length={displayWidth + 40}
                spanDirection="horizontal"
                stroke="black" />
            {/*bottom dotted line to show support*/}
            <DottedLine originX={frameCenterX}
                originY={frameCenterY + displayHeight / 2}
                length={displayWidth + 40}
                spanDirection="horizontal"
                stroke="black" />
            {/*z-axis*/}
            <DottedLine originX={frameCenterX}
                originY={frameCenterY}
                length={displayHeight + 2 * dottedLineExtension}
                spanDirection="vertical"
                stroke="darkgrey" />
            <text x={frameCenterX}
                y={frameCenterY + displayHeight / 2 + dottedLineExtension + 15}
                fill="darkgrey"
                textAnchor="middle"
                dominantBaseline="middle">Z</text>
            <text x={frameCenterX}
                y={frameCenterY - displayHeight / 2 - dottedLineExtension - 15}
                fill="darkgrey"
                textAnchor="middle"
                dominantBaseline="hanging">Z</text>
            {/*Vertical dimesion line*/}
            <DimensionLine originX={frameCenterX + displayWidth / 2 + 40}
                originY={frameCenterY}
                spanDirection="vertical"
                span={displayHeight}
                text={`${height} mm`}
                textAlignment="right"
                stroke="black" />
            {/*x-axis*/}
            <DottedLine originX={frameCenterX}
                originY={frameCenterY}
                length={displayWidth + 2 * dottedLineExtension}
                spanDirection="horizontal"
                stroke="darkgrey" />
            <text x={frameCenterX + displayWidth / 2 + dottedLineExtension + 10}
                y={frameCenterY}
                fill="darkgrey"
                textAnchor="middle"
                dominantBaseline="middle">X</text>
            <text x={frameCenterX - displayWidth / 2 - dottedLineExtension - 10}
                y={frameCenterY}
                fill="darkgrey"
                textAnchor="middle"
                dominantBaseline="middle">X</text>
            {/*horizontal dimesion line*/}
            <DimensionLine originX={frameCenterX}
                originY={frameCenterY - displayHeight / 2 - 40}
                spanDirection="horizontal"
                span={displayWidth}
                text={`${width} mm`}
                textAlignment="top"
                stroke="black" />
            {/*legend*/}
            <Legend originX={10}
                originY={frameCenterY}
                sideLength={5}
                verticalSpacing={20}
                items={legend} />
        </>
    )
}

export default RectangularColumnLongitudinalDiagram;