const ComponentHolder = ({ id, title, children }) => {
    return (
        <div id={id} className="_dashboardTile">
            <div className="_dashboardTileTitleBar">
                <label className="_tileHeading" style={{ backgroundColor: "transparent" }}>
                    {title}
                </label>
                <div>{Array.isArray(children) && children.length > 1 ? children[0] : null}</div>
            </div>
            <div className="_tileContentDiv">{Array.isArray(children) && children.length > 1 ? children[1] : children}</div>
        </div>
    );
};

export default ComponentHolder;
