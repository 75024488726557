import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(LineElement, PointElement, CategoryScale, Title, Tooltip, Legend);

export default function MohrsCircleDiagram({ normalStressX, normalStressY, shearStressXY, centreOfCircle, radiusOfCircle, yAxisLabel, xAxisLabel }) {
    const [chartData, setChartData] = useState({ datasets: [] });

    useEffect(() => {
        // 1) Generate circle points using the provided centre and radius
        const numPoints = 100;
        const circlePoints = [];
        for (let i = 0; i <= numPoints; i++) {
            const angle = (2 * Math.PI * i) / numPoints;
            const x = centreOfCircle.x + radiusOfCircle * Math.cos(angle);
            const y = centreOfCircle.y + radiusOfCircle * Math.sin(angle);
            circlePoints.push({ x, y });
        }

        // 2) Vertical line from (normalStressY, -shearStressXY) to (normalStressX, shearStressXY)
        const verticalLine = [
            { x: normalStressY, y: -shearStressXY },
            { x: normalStressX, y: shearStressXY },
        ];
        setChartData({
            datasets: [
                {
                    label: "Mohr’s Circle",
                    data: circlePoints,
                    borderColor: "grey",
                    pointRadius: 0,
                    showLine: true,
                    fill: false,
                    borderWidth: 1,
                },
                {
                    label: "Vertical Line",
                    data: verticalLine,
                    borderColor: "grey",
                    pointRadius: 0,
                    showLine: true,
                    fill: false,
                    borderWidth: 1,
                },
                {
                    // Centre of Circle dataset (for extra emphasis)
                    data: [centreOfCircle],
                    backgroundColor: "grey",
                    pointRadius: 2,
                    showLine: false,
                },
                {
                    // (σy, -τxy) point
                    data: [{ x: normalStressY, y: -shearStressXY }],
                    backgroundColor: "grey",
                    pointRadius: 2,
                    showLine: false,
                },
                {
                    // (σx, τxy) point
                    data: [{ x: normalStressX, y: shearStressXY }],
                    backgroundColor: "grey",
                    pointRadius: 2,
                    showLine: false,
                },
            ],
        });
    }, [normalStressX, normalStressY, shearStressXY, centreOfCircle, radiusOfCircle]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        plugins: {
            legend: { display: false },
            title: { display: false },
        },
        customLabels: {
            // add your dynamic values here
            centreOfCircle,
            normalStressX,
            normalStressY,
            shearStressXY,
        },
        scales: {
            x: {
                type: "linear",
                title: { display: true, text: xAxisLabel || "Normal Stress" },
                ticks: {
                    callback: function (value) {
                        return value.toFixed(2);
                    },
                },
                max: centreOfCircle.x + radiusOfCircle * (window.innerWidth * 0.00125),
                min: centreOfCircle.x - radiusOfCircle * (window.innerWidth * 0.00125),
            },
            y: {
                type: "linear",
                reverse: true,
                title: { display: true, text: yAxisLabel || "Shear Stress" },
                ticks: {
                    stepSize: 20,
                    callback: function (value) {
                        return value.toFixed(2);
                    },
                },
                min: centreOfCircle.y - radiusOfCircle - 20,
                max: centreOfCircle.y + radiusOfCircle + 20,
            },
        },
    };

    // Custom plugin to draw a vertical dashed line at x=0
    const customLine = {
        id: "customLine",
        beforeDraw: (chart) => {
            const ctx = chart.ctx;
            const xAxis = chart.scales.x;
            const yAxis = chart.scales.y;
            const x = xAxis.getPixelForValue(0);
            if (x) {
                ctx.save();
                ctx.beginPath();
                ctx.strokeStyle = "grey";
                ctx.setLineDash([5, 5]);
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.stroke();
                ctx.restore();
            }
        },
        afterDraw: (chart) => {
            const { centreOfCircle, normalStressX, normalStressY, shearStressXY } = chart.options.customLabels;
            const ctx = chart.ctx;
            const xAxis = chart.scales.x;
            const yAxis = chart.scales.y;
            ctx.save();
            ctx.fillStyle = "grey";
            ctx.font = "10px Arial";
            ctx.textAlign = "center";

            // Label for Centre of Circle
            const centreX = xAxis.getPixelForValue(centreOfCircle.x);
            const centreY = yAxis.getPixelForValue(centreOfCircle.y);
            ctx.fillText(`(${centreOfCircle.x.toFixed(2)}, ${centreOfCircle.y.toFixed(2)})`, centreX + 5, centreY - 10);

            // Label for (σy, -τxy) point
            const point1X = xAxis.getPixelForValue(normalStressY);
            const point1Y = yAxis.getPixelForValue(-shearStressXY);
            ctx.fillText(`(${normalStressY.toFixed(2)}, ${(-shearStressXY).toFixed(2)})`, point1X - 50, point1Y + 20);

            // Label for (σx, τxy) point
            const point2X = xAxis.getPixelForValue(normalStressX);
            const point2Y = yAxis.getPixelForValue(shearStressXY);
            ctx.fillText(`(${normalStressX.toFixed(2)}, ${shearStressXY.toFixed(2)})`, point2X + 50, point2Y - 10);

            ctx.restore();
        },
    };

    const width = "100%";
    const height = "100%";

    return (
        <div
            style={{
                width: width,
                height: height,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Line data={chartData} options={options} plugins={[customLine]} />
        </div>
    );
}
