import "./expandableSection.css";
import { useState } from "react";

function ExpandableSection({ title, children }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="expandable-section">
            <div className="section-header" onClick={toggleExpand}>
                <img alt = "expandableSectionImage" className="_expandableSectionImage" src={`${isExpanded ? "../assets/downArrow.png" : "../assets/rightArrow.png"}`} />
                <label className="_expandableSectionHeading">{title}</label>
                {/* {isExpanded ? '-' : '+'} {title} */}
            </div>
            <div className={`section-content ${isExpanded ? "expanded" : "collapsed"}`}>{children}</div>
        </div>
    );
}

export default ExpandableSection;
