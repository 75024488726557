import "./designPagesStyles.css";
import React, { useRef, useState } from "react";
import InputSheet from "../../components/inputSheet/inputSheet";
import axios from "axios";
import ComponentHolder from "../../components/NewComponents/ComponentHolder";
import ExpandableSection from "../../components/NewComponents/ExpandableSection";
import Buttons from "../../components/buttons/buttons";
import MohrsCircleDiagram from "../../components/diagrams/mohrsCircleDiagram";
import MohrsCircleChart from "../../components/chart/mohrsCircle/mohrsCircleChart";
import AppliedForcesAndDesignCapacities from "../../components/tables/appliedForcesAndDesignCapacities/appliedForcesAndDesignCapacitites";

function MohrsCircle() {
    const [inputs, setInputs] = useState({
        normalForceX: 100,
        normalForceY: 100,
        shearForceXY: 100,
        angle: 0,
    });

    // const [geometryInformationOverlayVisibility, setGeometryInformationOverlayVisibility] = useState(false);
    const [chartData, setChartData] = useState({
        normalStressX: 0,
        normalStressY: 0,
        shearStressXY: 0,
        centreOfCircle: { x: 0, y: 0 },
        radiusOfCircle: 0,
    });
    const [analytics, setAnalytics] = useState([]);
    const [hideData, setHideData] = useState("");
    const [stressTransformationTable, setStressTransformationTable] = useState([]);

    const inputDataRefs = {
        input1Ref: useRef(null),
        input2Ref: useRef(null),
        input3Ref: useRef(null),
        input4Ref: useRef(null),
    };

    // Input Variables
    const inputData = {
        input1Ref: ["Applied Forces", "Normal Force along X", ["number", inputDataRefs.input1Ref, 100], "kN"],
        input2Ref: ["Applied Forces", "Normal Force along Y", ["number", inputDataRefs.input2Ref, 100], "kN"],
        input3Ref: ["Applied Forces", "Shear Force on Y Plane", ["number", inputDataRefs.input3Ref, 100], "kN"],
        input4Ref: ["Angle of Rotation", "Angle", ["number", inputDataRefs.input4Ref, 0], "deg"],
    };

    //information overlay functions
    // const geometryInformationButtonClicked = () => {
    //     setGeometryInformationOverlayVisibility(true);
    // };

    const informationOverlayFunctions = [];

    const calculateButtonClicked = async () => {
        const newInputs = {
            normalForceX: inputDataRefs.input1Ref.current.value,
            normalForceY: inputDataRefs.input2Ref.current.value,
            shearForceXY: inputDataRefs.input3Ref.current.value,
            angle: inputDataRefs.input4Ref.current.value || 0,
        };
        // Update state immediately
        setInputs(newInputs);
        // Call API using the newInputs values directly
        try {
            const url = `/api/mohrsCircle?normalForceX=${newInputs.normalForceX}&normalForceY=${newInputs.normalForceY}&shearForceXY=${newInputs.shearForceXY}&angle=${newInputs.angle}`;
            const response = await axios.get(url);
            const data = response.data;
            setAnalytics(data.analytics);
            setChartData({
                normalStressX: data.normalStressX,
                normalStressY: data.normalStressY,
                shearStressXY: data.shearStressXY,
                centreOfCircle: data.centre,
                radiusOfCircle: data.radius,
            });
            setStressTransformationTable({
                normalForceX: ["Normal Force along X", newInputs.normalForceX + " kN", data.normalStressX.toFixed(2) + " kN"],
                normalForceY: ["Normal Force along Y", newInputs.normalForceY + " kN", data.normalStressY.toFixed(2) + " kN"],
                shearForceXY: ["Shear Force on Y Plane", newInputs.shearForceXY + " kN", data.shearStressXY.toFixed(2) + " kN"],
            });
        } catch (error) {
            setChartData({});
            setAnalytics([]);
            setHideData("Please check the input values and try again.");
        }
    };

    const informationButtonClicked = () => {
        window.open("../assets/mohrsCircle/designManual.pdf", "_blank");
    };

    // Title Bar Buttons
    const titleBarButtons = {
        calculationButton: ["Calculate", calculateButtonClicked],
        informationButton: ["Information", informationButtonClicked],
    };

    const renderedTitleBarButtons = [];
    Object.keys(titleBarButtons).forEach((key) => {
        renderedTitleBarButtons.push(<Buttons type="textButton" text={titleBarButtons[key][0]} fontColor="black" backgroundColor="" linkTo="" onClickFunction={titleBarButtons[key][1]} />);
    });

    return (
        <>
            <div className="_containerDiv">
                {/* Information overlays */}
                <div className="_designInputDiv">
                    <div className="_designInputsTitleBar">
                        <label className="_dashboardHeading">Design Inputs</label>
                    </div>
                    <div className="_designInputs">
                        <InputSheet inputData={inputData} updateCalculationsFunction={calculateButtonClicked} informationOverlayFunctions={informationOverlayFunctions} />
                    </div>
                </div>
                <div className="_designDashboardDiv">
                    <div className="_dashboardTitleBar">
                        <label className="_dashboardHeading" style={{ color: "black" }}>
                            Mohr's Circle Analysis
                        </label>
                        <div className="_dashboardButtons">{renderedTitleBarButtons}</div>
                    </div>

                    <div className="_dashboardTiles">
                        <div id="_dashboardLeftDiv" className="_dashboardSection">
                            <ComponentHolder id="dashboardTopLeftDiv" title="Diagram">
                                {analytics && analytics.length > 0 ? <MohrsCircleDiagram normalForceX={inputs.normalForceX} normalForceY={inputs.normalForceY} shearForceXY={inputs.shearForceXY} /> : <div>Press "Calculate" or change design inputs to show analysis results.</div>}
                            </ComponentHolder>
                            <ComponentHolder id="dashboardBottomLeftDiv" title="Mohr's Circle">
                                {analytics && analytics.length > 0 ? <MohrsCircleChart normalStressX={chartData.normalStressX} normalStressY={chartData.normalStressY} shearStressXY={chartData.shearStressXY} centreOfCircle={chartData.centreOfCircle} radiusOfCircle={chartData.radiusOfCircle} yAxisLabel="Shear Stress (kN)" xAxisLabel="Normal Stress (kN)" /> : <div>Press "Calculate" or change design inputs to show analysis results.</div>}
                            </ComponentHolder>
                        </div>
                        <div id="_dashBoardRightDiv" className="_dashboardSection">
                            <ComponentHolder id="dashboardTopRightDiv" title="Analysis Results">
                                <div>{analytics && analytics.length > 0 ? analytics.map((section, index) => <ExpandableSection key={index} title={section.heading} data={section.data} toFixed={2} />) : <div>Press "Calculate" or change design inputs to show analysis results.</div>}</div>
                            </ComponentHolder>
                            <ComponentHolder id="dashboardBottomRightDiv" title="Stress Transformation Table">
                                {analytics && Object.keys(analytics).length > 0 ? <AppliedForcesAndDesignCapacities heading={["Description", "Original (0°)", `Transformed(${inputs.angle}°)`]} data={stressTransformationTable} /> : <div>Press "Calculate" or change design inputs to show analysis results.</div>}
                            </ComponentHolder>
                        </div>
                    </div>
                    <div className="_dashboardStatusBar">
                        <label>{hideData}</label>
                        <label>Structura Pro</label>
                    </div>
                </div>
                {/* {geometryInformationOverlayVisibility && (
                    <InformationOverlay
                        informationOverlayHeading="Geometric Properties"
                        informationOverlayCaption={["Geometric Properties: Longitudinal View"]}
                        informationOverlayImageSrc={["..\\assets\\beamAnalysis\\images\\geometricInformationOverlay.png"]}
                        closeInformationOverlays={() => {
                            setGeometryInformationOverlayVisibility(!geometryInformationOverlayVisibility);
                        }}
                    />
                )} */}
            </div>
        </>
    );
}

export default MohrsCircle;
