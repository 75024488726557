import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(BarElement, CategoryScale, Title, Tooltip, Legend);

const BeamAnalysisEnergyBar = ({ data, currentCondition }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Energy Stored (MJ)",
                data: [],
                backgroundColor: [],
                borderWidth: 1,
            },
        ],
    });
    const endConditions = {
        fixedFixed: "Fix-Fix",
        fixedPinned: "Fix-Pin",
        fixedFree: "Fix-Free",
        pinnedPinned: "Pin-Pin",
    };

    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            setChartData({
                labels: Object.keys(data).map((key) => endConditions[key]),
                datasets: [
                    {
                        label: "Energy Stored (MJ)",
                        data: Object.values(data),
                        backgroundColor: Object.keys(data).map((key) => {
                            return key === currentCondition ? "rgb(91, 91, 91)" : "rgb(193, 193, 193)";
                        }),
                        borderWidth: 1,
                    },
                ],
            });
        }
    }, [currentCondition, data]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: "top",
            },
            title: {
                display: false,
                text: "Energy Stored for Different End Conditions",
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: data.fixedFixed ? data.fixedFixed * 1.02 : undefined,
                title: {
                    display: true,
                    text: "Energy Stored (MJ)",
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
            x: {
                title: {
                    display: true,
                    text: "End Condition",
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default BeamAnalysisEnergyBar;
