import React, { useEffect, useRef, useState } from "react";
import { Arrow, Layer, Line, Rect, Stage, Text } from "react-konva";

const MohrsCircleDiagram = ({ normalForceX, normalForceY, shearForceXY }) => {
    const containerRef = useRef(null);
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
    const [diagramParams, setDiagramParams] = useState(null);

    // Update stage dimensions for responsiveness
    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                const { offsetWidth, offsetHeight } = containerRef.current;
                setStageSize({ width: offsetWidth, height: offsetHeight });
            }
        };

        updateSize();
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    // Compute diagram parameters when stageSize updates
    useEffect(() => {
        if (stageSize.width === 0 || stageSize.height === 0) return;

        const margin = 50;
        const displayHeight = stageSize.height - 2 * margin;
        const centerX = stageSize.width / 2;

        const baseWidth = 800;
        const baseHeight = 600;
        const scale = Math.min(stageSize.width / baseWidth, stageSize.height / baseHeight);

        const squareX = centerX - 135 * scale;
        const squareY = margin - 135 * scale + displayHeight / 2;
        const squareSize = 270 * scale;

        const horizontalLineOffset = squareSize * 0.148; // similar to 40/270
        const horizontalArrowXDiff = squareSize * 0.0556; // similar to 15/270
        const horizontalArrowYDiff = squareSize * 0.0259; // similar to 7/270

        const verticalLineOffset = squareSize * 0.148; // similar to 40/270
        const verticalArrowXDiff = squareSize * 0.037; // similar to 10/270
        const verticalArrowYDiff = squareSize * 0.0556; // similar to 15/270

        setDiagramParams({
            margin,
            displayHeight,
            centerX,
            scale,
            squareX,
            squareY,
            squareSize,
            horizontalLineOffset,
            horizontalArrowXDiff,
            horizontalArrowYDiff,
            verticalLineOffset,
            verticalArrowXDiff,
            verticalArrowYDiff,
        });
    }, [stageSize]);

    if (stageSize.width === 0 || stageSize.height === 0 || !diagramParams) {
        return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
    }

    return (
        <div ref={containerRef} style={{ width: "100%", height: "100%" }}>
            <Stage width={stageSize.width} height={stageSize.height}>
                <Layer>
                    {/* Shear force horizontal lines */}
                    <Text 
                        x={
                            diagramParams.squareX +
                            diagramParams.squareSize +
                            diagramParams.verticalLineOffset +
                            20 * diagramParams.scale
                        }
                        y={diagramParams.squareY}
                        text={`${shearForceXY} kN`}
                        fontSize={20 * diagramParams.scale}
                    />

                    {/* Top horizontal line */}
                    <Line
                        points={[
                            diagramParams.squareX,
                            diagramParams.squareY - diagramParams.horizontalLineOffset,
                            diagramParams.squareX + diagramParams.squareSize,
                            diagramParams.squareY - diagramParams.horizontalLineOffset,
                            diagramParams.squareX + diagramParams.squareSize - diagramParams.horizontalArrowXDiff,
                            diagramParams.squareY - diagramParams.horizontalLineOffset - diagramParams.horizontalArrowYDiff,
                        ]}
                        stroke="red"
                        strokeWidth={1.5}
                    />

                    {/* Bottom horizontal line */}
                    <Line
                        points={[
                            diagramParams.squareX + diagramParams.squareSize,
                            diagramParams.squareY + diagramParams.squareSize + diagramParams.horizontalLineOffset,
                            diagramParams.squareX,
                            diagramParams.squareY + diagramParams.squareSize + diagramParams.horizontalLineOffset,
                            diagramParams.squareX + diagramParams.horizontalArrowXDiff,
                            diagramParams.squareY + diagramParams.squareSize + diagramParams.horizontalLineOffset + diagramParams.horizontalArrowYDiff,
                        ]}
                        stroke="red"
                        strokeWidth={1.5}
                    />

                    {/* Normal force vertical lines */}
                    {/* Left vertical line */}
                    <Line
                        points={[
                            diagramParams.squareX - diagramParams.verticalLineOffset,
                            diagramParams.squareY,
                            diagramParams.squareX - diagramParams.verticalLineOffset,
                            diagramParams.squareY + diagramParams.squareSize,
                            diagramParams.squareX - diagramParams.verticalLineOffset - diagramParams.verticalArrowXDiff,
                            diagramParams.squareY + diagramParams.squareSize - diagramParams.verticalArrowYDiff,
                        ]}
                        stroke="green"
                        strokeWidth={1.5}
                    />
                    {/* Right vertical line */}
                    <Line
                        points={[
                            diagramParams.squareX + diagramParams.squareSize + diagramParams.verticalLineOffset,
                            diagramParams.squareY + diagramParams.squareSize,
                            diagramParams.squareX + diagramParams.squareSize + diagramParams.verticalLineOffset,
                            diagramParams.squareY,
                            diagramParams.squareX + diagramParams.squareSize + diagramParams.verticalLineOffset + diagramParams.verticalArrowXDiff,
                            diagramParams.squareY + diagramParams.verticalArrowYDiff,
                        ]}
                        stroke="green"
                        strokeWidth={1.5}
                    />

                    {/* Lines perpendicular to the square's sides, normal force */}
                    {/* along the y-axis */}
                    <Text
                        x={diagramParams.squareX + diagramParams.squareSize / 2 - 75 * diagramParams.scale}
                        y={diagramParams.squareY - 150 * diagramParams.scale}
                        text={`${normalForceY} kN`}
                        fontSize={20 * diagramParams.scale}
                        fill={normalForceY > 0 ? "green" : "red"}
                    />
                    <Arrow
                        points={[
                            diagramParams.squareX + diagramParams.squareSize / 2,
                            diagramParams.squareY - 80 * diagramParams.scale,
                            diagramParams.squareX + diagramParams.squareSize / 2,
                            diagramParams.squareY - 140 * diagramParams.scale,
                        ]}
                        stroke="green"
                        strokeWidth={1.5}
                        fill="green"
                    />
                    <Arrow
                        points={[
                            diagramParams.squareX + diagramParams.squareSize / 2,
                            diagramParams.squareY + diagramParams.squareSize + 60 * diagramParams.scale,
                            diagramParams.squareX + diagramParams.squareSize / 2,
                            diagramParams.squareY + diagramParams.squareSize + 120 * diagramParams.scale,
                        ]}
                        stroke="green"
                        strokeWidth={1.5}
                        fill="green"
                    />

                    {/* along the x-axis */}
                    <Text
                        x={diagramParams.squareX + diagramParams.squareSize + 150 * diagramParams.scale}
                        y={diagramParams.squareY + diagramParams.squareSize / 2}
                        text={`${normalForceX} kN`}
                        fontSize={20 * diagramParams.scale}
                        fill="red"
                    />
                    <Arrow
                        points={[
                            diagramParams.squareX - 100 * diagramParams.scale,
                            diagramParams.squareY + diagramParams.squareSize / 2 - 20 * diagramParams.scale,
                            diagramParams.squareX - 160 * diagramParams.scale,
                            diagramParams.squareY + diagramParams.squareSize / 2 - 20 * diagramParams.scale,
                        ]}
                        stroke="red"
                        strokeWidth={1.5}
                        fill="red"
                    />
                    <Arrow
                        points={[
                            diagramParams.squareX + diagramParams.squareSize + 100 * diagramParams.scale,
                            diagramParams.squareY + diagramParams.squareSize / 2 - 20 * diagramParams.scale,
                            diagramParams.squareX + diagramParams.squareSize + 160 * diagramParams.scale,
                            diagramParams.squareY + diagramParams.squareSize / 2 - 20 * diagramParams.scale,
                        ]}
                        stroke="red"
                        strokeWidth={1.5}
                        fill="red"
                    />

                    {/* Square */}
                    <Rect
                        x={diagramParams.squareX}
                        y={diagramParams.squareY}
                        width={diagramParams.squareSize}
                        height={diagramParams.squareSize}
                        stroke="black"
                    />
                </Layer>
            </Stage>
        </div>
    );
};

export default MohrsCircleDiagram;